
      import pug from 'pug-runtime';

      function episodesFormsMinisCards(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (episode, getMIMEType, minis, resimg, toTextOnly) {
      pug_html = pug_html + "\u003Cdiv class=\"card-deck-wrapper\" data-ajax-form-key=\"episode-form-minis-cards\"\u003E\u003Cdiv class=\"row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2\"\u003E";
if (minis) {
// iterate minis
;(function(){
  var $$obj = minis;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var mini = $$obj[key];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E\u003Cform" + (" class=\"card js-form\""+" method=\"put\""+pug.attr("action", "/episodes/" + episode.id + "/minis/" + key, true, false)+" enctype=\"multipart\u002Fform-data\" data-ressource-type=\"minis\"") + "\u003E\u003Cinput" + (" type=\"hidden\" name=\"number\""+pug.attr("value", mini.number, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E";
if (!mini.isUsed) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger me-1\" type=\"submit\" formmethod=\"delete\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-info js-copy-field\""+" type=\"button\" data-copy-field=\"minis\""+pug.attr("data-copy-key", key, true, false)) + "\u003E\u003Ci class=\"fa-regular fa-copy\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-right\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = mini.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
if (mini.img) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", mini.img, true, false)+pug.attr("alt", toTextOnly(mini.alt), true, false)) + "\u002F\u003E";
}
if (mini.doc) {
if (mini.doc.img) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(mini.doc.img, "480"), true, false)+pug.attr("alt", mini.doc.alt, true, false)) + "\u002F\u003E";
}
if (mini.model === "Document") {
pug_html = pug_html + "\u003Cdiv class=\"card-header overflow-hidden text-center\"\u003E";
// iterate mini.doc.files
;(function(){
  var $$obj = mini.doc.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var doc = $$obj[pug_index1];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var doc = $$obj[pug_index1];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if (mini.model === "Video") {
pug_html = pug_html + "\u003Cdiv class=\"card-header overflow-hidden\"\u003E\u003Cfigure class=\"embed-responsive embed-responsive-16by9\"\u003E\u003Cvideo class=\"embed-responsive-item\" controls=\"true\" preload=\"none\"\u003E";
// iterate mini.doc.files
;(function(){
  var $$obj = mini.doc.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var video = $$obj[pug_index2];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", video, true, false)+pug.attr("type", getMIMEType(video), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var video = $$obj[pug_index2];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", video, true, false)+pug.attr("type", getMIMEType(video), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fvideo\u003E\u003C\u002Ffigure\u003E\u003C\u002Fdiv\u003E";
}
if (mini.model === "MusicSoundtrack") {
pug_html = pug_html + "\u003Cfigure class=\"card-header overflow-hidden\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate mini.doc.files
;(function(){
  var $$obj = mini.doc.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var audio = $$obj[pug_index3];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index3];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
}
if (mini.model === "PlaylistSoundtrack") {
pug_html = pug_html + "\u003Cdiv class=\"card-header overflow-hidden text-center\"\u003E\u003Ca" + (" class=\"text-nowrap\""+pug.attr("href", "https://www.youtube.com/embed/videoseries?list=" + mini.doc.playlists.youtube, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-music\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = "https://www.youtube.com/embed/videoseries?list=" + mini.doc.playlists.youtube) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["card-body pb-0" + (!mini.img ? " mt-3" : "")], [true]), false, false)) + "\u003E\u003Cdiv class=\"row form-required mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003EURL\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E";
if (mini.doc && !mini.href) {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control\""+" type=\"text\" name=\"href\""+pug.attr("placeholder", mini.doc.href, true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control\""+" type=\"text\" name=\"href\""+pug.attr("value", mini.href, true, false)) + "\u002F\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row form-required mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ETitre\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"30\" data-edith-resizable=\"true\" data-edith-toolbar-mode=\"nolinks\"\u003E";
if (mini.doc && !mini.title) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"title\""+pug.attr("placeholder", mini.doc.title, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"title\""+pug.attr("value", mini.title, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003EImage\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cinput class=\"form-control-file\" type=\"file\" accept=\"image\u002Fgif,image\u002Fjpeg,image\u002Fpng\" name=\"img\"\u002F\u003E\u003C\u002Fdiv\u003E";
if (mini.doc) {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control col-12\""+" type=\"hidden\" name=\"model\""+pug.attr("value", mini.model, true, false)) + "\u002F\u003E\u003Cinput" + (" class=\"form-control col-12\""+" type=\"hidden\" name=\"doc\""+pug.attr("value", mini.doc.id, true, false)) + "\u002F\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row form-required mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ETexte\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"180\"\u003E";
if (mini.doc && !mini.subtitle) {
if (mini.doc.files) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"subtitle\""+pug.attr("placeholder", mini.doc.desc, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"subtitle\""+pug.attr("placeholder", mini.doc.subtitle, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"subtitle\""+pug.attr("value", mini.subtitle, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ELégende\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"180\"\u003E";
if (mini.doc && !mini.caption) {
if (mini.doc.files) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"caption\""+pug.attr("placeholder", mini.doc.desc, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput type=\"hidden\" name=\"caption\" autocomplete=\"off\"\u002F\u003E";
}
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"caption\""+pug.attr("value", mini.caption, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Chr\u002F\u003E\u003Cdiv class=\"card-body pt-0\"\u003E\u003Cinput class=\"btn btn-primary d-block mx-auto\" type=\"submit\" value=\"Enregistrer\"\u002F\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var mini = $$obj[key];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E\u003Cform" + (" class=\"card js-form\""+" method=\"put\""+pug.attr("action", "/episodes/" + episode.id + "/minis/" + key, true, false)+" enctype=\"multipart\u002Fform-data\" data-ressource-type=\"minis\"") + "\u003E\u003Cinput" + (" type=\"hidden\" name=\"number\""+pug.attr("value", mini.number, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E";
if (!mini.isUsed) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger me-1\" type=\"submit\" formmethod=\"delete\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-info js-copy-field\""+" type=\"button\" data-copy-field=\"minis\""+pug.attr("data-copy-key", key, true, false)) + "\u003E\u003Ci class=\"fa-regular fa-copy\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-right\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = mini.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
if (mini.img) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", mini.img, true, false)+pug.attr("alt", toTextOnly(mini.alt), true, false)) + "\u002F\u003E";
}
if (mini.doc) {
if (mini.doc.img) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(mini.doc.img, "480"), true, false)+pug.attr("alt", mini.doc.alt, true, false)) + "\u002F\u003E";
}
if (mini.model === "Document") {
pug_html = pug_html + "\u003Cdiv class=\"card-header overflow-hidden text-center\"\u003E";
// iterate mini.doc.files
;(function(){
  var $$obj = mini.doc.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var doc = $$obj[pug_index4];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var doc = $$obj[pug_index4];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if (mini.model === "Video") {
pug_html = pug_html + "\u003Cdiv class=\"card-header overflow-hidden\"\u003E\u003Cfigure class=\"embed-responsive embed-responsive-16by9\"\u003E\u003Cvideo class=\"embed-responsive-item\" controls=\"true\" preload=\"none\"\u003E";
// iterate mini.doc.files
;(function(){
  var $$obj = mini.doc.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index5 = 0, $$l = $$obj.length; pug_index5 < $$l; pug_index5++) {
        var video = $$obj[pug_index5];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", video, true, false)+pug.attr("type", getMIMEType(video), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index5 in $$obj) {
      $$l++;
      var video = $$obj[pug_index5];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", video, true, false)+pug.attr("type", getMIMEType(video), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fvideo\u003E\u003C\u002Ffigure\u003E\u003C\u002Fdiv\u003E";
}
if (mini.model === "MusicSoundtrack") {
pug_html = pug_html + "\u003Cfigure class=\"card-header overflow-hidden\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate mini.doc.files
;(function(){
  var $$obj = mini.doc.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index6 = 0, $$l = $$obj.length; pug_index6 < $$l; pug_index6++) {
        var audio = $$obj[pug_index6];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index6 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index6];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
}
if (mini.model === "PlaylistSoundtrack") {
pug_html = pug_html + "\u003Cdiv class=\"card-header overflow-hidden text-center\"\u003E\u003Ca" + (" class=\"text-nowrap\""+pug.attr("href", "https://www.youtube.com/embed/videoseries?list=" + mini.doc.playlists.youtube, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-music\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = "https://www.youtube.com/embed/videoseries?list=" + mini.doc.playlists.youtube) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["card-body pb-0" + (!mini.img ? " mt-3" : "")], [true]), false, false)) + "\u003E\u003Cdiv class=\"row form-required mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003EURL\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E";
if (mini.doc && !mini.href) {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control\""+" type=\"text\" name=\"href\""+pug.attr("placeholder", mini.doc.href, true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control\""+" type=\"text\" name=\"href\""+pug.attr("value", mini.href, true, false)) + "\u002F\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row form-required mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ETitre\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"30\" data-edith-resizable=\"true\" data-edith-toolbar-mode=\"nolinks\"\u003E";
if (mini.doc && !mini.title) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"title\""+pug.attr("placeholder", mini.doc.title, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"title\""+pug.attr("value", mini.title, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003EImage\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cinput class=\"form-control-file\" type=\"file\" accept=\"image\u002Fgif,image\u002Fjpeg,image\u002Fpng\" name=\"img\"\u002F\u003E\u003C\u002Fdiv\u003E";
if (mini.doc) {
pug_html = pug_html + "\u003Cinput" + (" class=\"form-control col-12\""+" type=\"hidden\" name=\"model\""+pug.attr("value", mini.model, true, false)) + "\u002F\u003E\u003Cinput" + (" class=\"form-control col-12\""+" type=\"hidden\" name=\"doc\""+pug.attr("value", mini.doc.id, true, false)) + "\u002F\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"row form-required mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ETexte\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"180\"\u003E";
if (mini.doc && !mini.subtitle) {
if (mini.doc.files) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"subtitle\""+pug.attr("placeholder", mini.doc.desc, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"subtitle\""+pug.attr("placeholder", mini.doc.subtitle, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"subtitle\""+pug.attr("value", mini.subtitle, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mb-3\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ELégende\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"180\"\u003E";
if (mini.doc && !mini.caption) {
if (mini.doc.files) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"caption\""+pug.attr("placeholder", mini.doc.desc, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cinput type=\"hidden\" name=\"caption\" autocomplete=\"off\"\u002F\u003E";
}
}
else {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"caption\""+pug.attr("value", mini.caption, true, false)+" autocomplete=\"off\"") + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Chr\u002F\u003E\u003Cdiv class=\"card-body pt-0\"\u003E\u003Cinput class=\"btn btn-primary d-block mx-auto\" type=\"submit\" value=\"Enregistrer\"\u002F\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "getMIMEType" in locals_for_with ?
        locals_for_with.getMIMEType :
        typeof getMIMEType !== 'undefined' ? getMIMEType : undefined, "minis" in locals_for_with ?
        locals_for_with.minis :
        typeof minis !== 'undefined' ? minis : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default episodesFormsMinisCards;
    