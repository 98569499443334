import $ from "jquery";
import groupBy from "lodash/groupBy";

$(function () {
  // Initialize Emojis Button
  $(".emojis-toolbox .btn").on("click", function () {
    // Check if the Modal is already loaded
    if ($("#emojis-modal").length > 0) {
      // Open the Emojis Modal
      $("#emojis-modal").modal("show");
      return;
    }

    // Get the Emojis JSON
    $.getJSON("/dist/jsons/emoji.json").then(function (emojis) {
      // Group Emojis by Categories
      const categories = groupBy(emojis, function (emoji) {
        return emoji.category;
      });

      // Add the Emojis Modal
      $(document.body).append(
        $.renderTemplate("modalsEmojis", {
          categories: categories,
        })
      );

      // Open the Emojis Modal
      $("#emojis-modal").modal("show");
    });
  });
});
