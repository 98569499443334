/* globals google */
import $ from "jquery";
import { Loader } from "@googlemaps/js-api-loader";

$(function () {
  // Geolocation Picker Selector
  const geolocationPickerSelector = ".js-geolocationpicker";

  // Initialize Geolocation Pickers
  function initializeGeolocationPickers() {
    // Iterate through Geolocation Pickers Elements
    $(geolocationPickerSelector).each(function () {
      // Variables
      const $el = $(this);
      const $form = $el.parents("form");

      // Create the autocomplete object, restricting the search predictions to geographical location types.
      const autocomplete = new google.maps.places.Autocomplete(this, { types: ["geocode", "establishment"] });
      autocomplete.setFields(["address_components", "geometry"]);
      autocomplete.addListener("place_changed", function () {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();

        // Save the location
        $form
          .find("input[name=" + $el.data("geolocationpickerTargetLatitude") + "]")
          .val(place.geometry.location.lat());
        $form
          .find("input[name=" + $el.data("geolocationpickerTargetLongitude") + "]")
          .val(place.geometry.location.lng());
      });
    });
  }

  function loadGoogleMapsJsSDK() {
    // Load the Google Maps JS SDK
    const loader = new Loader({
      apiKey: "AIzaSyACN37GqYj6sAhsI5sqJ1Jrg1RuTmX9opk",
      libraries: ["places"],
    });
    loader.load().then(() => {
      // Initialize Geolocation Pickers
      initializeGeolocationPickers();
    });
  }

  // Check if there is geolocation picker in the page
  if ($(geolocationPickerSelector).length > 0) {
    // Load the Google Maps JS SDK
    loadGoogleMapsJsSDK();
  }
});
