import $ from "jquery";

$(function () {
  // Change Color Modal
  $("body").on("show.bs.modal.color", ".colors-page #update-color-modal", function (event) {
    // Get the Button that Triggered the Modal, the Modal & Color Information
    const $button = $(event.relatedTarget);
    const $modal = $(this);
    const color = $button.data("color");

    // Update Elements in the Modal
    $modal.find("form").attr("action", "/couleurs/" + color._id);
    $modal.find(".js-color-main").colorpicker("setValue", color.main);
    $modal.find(".js-color-light").colorpicker("setValue", color.light);
  });
});
