import $ from "jquery";

// Extend jQuery to Provide Method to get Text Content of a Node but not of its children
$.fn.textContent = function () {
  // Output String
  let text = "";

  // Iterate through Children Nodes
  this.contents().each(function (idx, node) {
    // Check if it's a Text Node
    if (node.nodeType === Node.TEXT_NODE) {
      // Append the Text
      text += node.nodeValue;
    }
  });

  // Return the String
  return text;
};
