import $ from "jquery";
import { toJpeg } from "html-to-image";
import { throttle } from "lodash";

function getSnakeSize() {
  return {
    width: parseInt($(".toolbox-page #snake input[name=width]").val(), 10),
    height: parseInt($(".toolbox-page #snake input[name=height]").val(), 10),
  };
}

function refreshSnakeRatio() {
  const { width, height } = getSnakeSize();

  const $preview = $(".toolbox-page #snake .js-snake-preview > div:first-of-type");
  const previewWidth = $preview.width();

  $preview.css("height", `${Math.round((previewWidth * height) / width)}px`);
}

$(function () {
  $(".toolbox-page #snake input[name=width], .toolbox-page #snake input[name=height]").on(
    "change.toolbox",
    refreshSnakeRatio
  );

  $(".toolbox-page #snake .edith-editor").on(
    "input.toolbox",
    ".edith-visual, .edith-code",
    throttle(
      function (event) {
        const $editor = $(event.currentTarget).closest(".edith-editor");
        const $input = $editor.find("input");

        $(`.toolbox-page #snake .js-snake-preview .${$input.attr("name")}`).html($editor.getEdithEditorContent());
      },
      500,
      { leading: true, trailing: true }
    )
  );

  $(".toolbox-page #snake input[name=color]").on(
    "change.toolbox",
    throttle(
      function (event) {
        const $input = $(event.currentTarget);

        $(`.toolbox-page #snake .js-snake-preview .cover-obsession figure`).css("backgroundColor", $input.val());
        $(`.toolbox-page #snake .js-snake-preview .cover-episode .snake-1`).css("borderColor", $input.val());
        $(`.toolbox-page #snake .js-snake-preview .cover-episode .overline`).css("color", $input.val());
      },
      500,
      { leading: true, trailing: true }
    )
  );

  $(".toolbox-page #snake input[name=image]").on("change.toolbox", function (event) {
    const $input = $(event.currentTarget);

    if ($input.get(0).files.length > 0) {
      // Read the File
      const reader = new FileReader();
      reader.readAsDataURL($input.get(0).files[0]);

      // Load the Image inside the Preview
      reader.addEventListener(
        "load",
        function () {
          $(`.toolbox-page #snake .js-snake-preview img`).attr("src", reader.result);
        },
        false
      );
    }
  });

  $(".toolbox-page #snake select[name=snake]").on("change.toolbox", function (event) {
    const $input = $(event.currentTarget);

    $(`.toolbox-page #snake .js-snake-preview .cover-episode`)
      .removeClass(["snake-bottom", "snake-top"])
      .addClass($input.val());
  });

  $(".toolbox-page #snake input[name=size]").on("change.toolbox", function (event) {
    const $input = $(event.currentTarget);

    $(`.toolbox-page #snake `).css("--lj-tb-snake-size-factor", $input.val());
  });

  $(".toolbox-page #snake .js-download-snake").on("click", function (event) {
    const $button = $(event.currentTarget);
    const { width, height } = getSnakeSize();

    toJpeg($button.parents(".js-snake-preview").find(".cover").get(0), {
      backgroundColor: "#00000000",
      canvasWidth: width,
      canvasHeight: height,
      pixelRatio: 1,
      quality: 0.95,
    }).then(function (dataUrl) {
      const link = document.createElement("a");
      link.download = "snake.jpeg";
      link.href = dataUrl;
      link.click();
    });
  });

  refreshSnakeRatio();
});
