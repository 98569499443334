import $ from "jquery";

$(function () {
  // Balance the number of elements between the containers
  function balanceDragulaMultipleContainers() {
    const $parent = $(".home-page div[data-multiple-container=home]");
    const $containers = $parent.find("[data-drag-and-drop-container]");

    // Check if the container has too much/not enough elements
    $containers.each((idx, container) => {
      const $current = $(container);
      const $next = $containers.eq(idx + 1);

      // Check if the container is the last
      if ($next.length === 0) {
        return;
      }

      // If the container isn’t the last
      const children = $current.children();
      const max = $current.data("multipleContainerLength");
      const diff = children.length - max;
      if (diff > 0) {
        $next.prepend(children.slice(max, max + diff));
      } else if ($current.data("containerMin") === true && diff < 0) {
        $current.append($next.children().slice(0, -diff));
      }
    });
  }
  $("body").on("dragulaDrop.home", ".home-page div[data-multiple-container=home]", balanceDragulaMultipleContainers);

  // //////////////////////
  // DELETE & ARROW BUTTONS
  // Listen Click Events on .btn-danger Buttons on Card Elements (Master + Une + Slider cards)
  $("body").on("click.home", ".home-page .card .btn-danger", function (event) {
    // Remove the Card From the DOM
    $(event.currentTarget).closest(".card").parent().remove();

    // Balance elements between dragula containers
    balanceDragulaMultipleContainers();
  });

  // Listen Click Events on .btn-danger Buttons on Body Elements (Slider + Cover)
  $("body").on(
    "click.home",
    [
      ".home-page div[data-home-area='une'] .slider .btn-group-vertical > .btn-danger",
      ".home-page div[data-home-area='une'] .cover .btn-group-vertical > .btn-danger",
    ].join(", "),
    function (event) {
      // Remove the Card From the DOM
      $(".home-page #" + $(event.currentTarget).attr("data-une-element-id")).remove();
    }
  );

  // Listen Click Events on .btn-arrow Buttons on Body Elements (Slider + Cover)
  $("body").on("click.home", ".home-page div[data-home-area='une'] .btn-arrow", function (event) {
    // Select the Body Element
    const $bodyElement = $(".home-page #" + $(event.currentTarget).attr("data-une-element-id"));

    // Check the arrow direction
    if ($(event.currentTarget).data("arrowDirection") === "up") {
      // If we reached the top of the container
      if ($bodyElement.prev().length === 0) {
        return;
      }
      // Move the Element before its sibling
      $bodyElement.insertBefore($bodyElement.prev("[data-une-element-area]"));
    } else if ($(event.currentTarget).data("arrowDirection") === "down") {
      // If we reached the end of the container
      if ($bodyElement.next().length === 0) {
        return;
      }
      // Move the Element after its sibling
      $bodyElement.insertAfter($bodyElement.next("[data-une-element-area]"));
    }

    // Scroll To the Element
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $bodyElement.offset().top - 200,
      },
      0
    );
  });

  // /////////////////
  // ADD METAPERSON MODAL
  // Listen show events on the Modal
  $("body").on("show.bs.modal.home", ".home-page #add-metaperson-card", function (event) {
    const $button = $(event.relatedTarget);
    const $slider = $button.closest(".slider");
    const $modal = $(this);

    // Add the Deck Id data
    $modal.data("uneElementId", $button.closest("[data-une-element-area]").attr("id"));

    // Reset Data
    $modal.find(".js-add-metaperson-card-next label").html("");
    $modal.find(".js-add-metaperson-card-next select").html("");

    // Hide the next part o the form
    $modal.find(".js-add-metaperson-card-next").addClass("d-none");

    // Reset Autocomplete Inputs
    $modal.find(".js-autocomplete").val("");

    // Copy the title of the slider
    $modal.find("h4.modal-title span").html($slider.find("input[name=slider-title]").val());
  });

  // Listen autocomplete events on the Modal
  $("body").on(
    "autocomplete.selected.home",
    ".home-page #add-metaperson-card .js-autocomplete",
    function (event, $selected) {
      // Get the Modal & the Dropdown input
      const $modal = $(event.currentTarget).parents(".modal");
      const $dropdown = $selected.closest(".dropdown").find(".js-autocomplete");

      $.ajax({
        url: `${$selected.data("endpoint")}/card/${$selected.data("id")}?select=people`,
        method: "GET",
        dataType: "json",
        headers: {
          Accept: "application/json",
        },
      }).done(function (data) {
        // Update the Autocomplete Input
        $dropdown.val(data.name);

        // Get form elements
        const $label = $modal.find(".js-add-metaperson-card-next label");
        const $select = $modal.find(".js-add-metaperson-card-next select");

        // Check if it's a MetaPerson or an Obsession
        if (data.modelName === "Obsession") {
          // Set the label
          $label.html("Personnage de l’Obsession");

          // Clone the Obsession without People
          let obsession = Object.assign({}, data);
          delete obsession.people;
          delete obsession.episodes;
          obsession = JSON.stringify(obsession);

          // Set the select
          $select.html(
            data.people
              .map((people) => {
                // Option text & value
                const inputText = people.title;
                const inputValue = people.id;

                // Extract the Metaperson
                let metaperson = people.metaperson;
                delete people.metaperson;
                metaperson = JSON.stringify(metaperson);

                // Stringify the People
                people = JSON.stringify(people);

                // Return the input
                return `<option value='${inputValue}' data-metaperson='${metaperson}' data-obsession='${obsession}' data-people='${people}'>${inputText}</option>`;
              })
              .join("")
          );
        } else {
          // Set the label
          $label.html("Obsession pour le Personnage");

          // Clone the MetaPerson without People
          let metaperson = Object.assign({}, data);
          delete metaperson.people;
          metaperson = JSON.stringify(metaperson);

          // Set the select
          $select.html(
            data.people
              .map((people) => {
                // Option text & value
                const inputText = people.obsession.title;
                const inputValue = people.id;

                // Extract the Obsession
                let obsession = people.obsession;
                delete people.obsession;
                delete obsession.episodes;
                obsession = JSON.stringify(obsession);

                // Stringify the People
                people = JSON.stringify(people);

                // Return the input
                return `<option value='${inputValue}' data-metaperson='${metaperson}' data-obsession='${obsession}' data-people='${people}'>${inputText}</option>`;
              })
              .join("")
          );
        }

        // Show the next part of the form
        $modal.find(".js-add-metaperson-card-next").removeClass("d-none");
      });
    }
  );

  // Listen Add Element click events on the Modal
  $("body").on("click.home.modal", ".home-page #add-metaperson-card button.btn-primary", function (event) {
    // Get the Modal & the Slider
    const $modal = $(event.currentTarget).parents(".modal");
    const $deck = $(`.home-page [data-home-area=une] #${$modal.data("uneElementId")} .slider-container .row`);

    // Get form elements
    const $select = $modal.find(".js-add-metaperson-card-next select");
    const $value = $select.find("option:selected");
    const payload = $value.data();

    // Create the Card
    const $card = $("<div/>", { class: "col" });
    $card.append(
      $.renderTemplate("homeCardsPeople", {
        people: payload.people,
        obsession: payload.obsession,
        metaperson: payload.metaperson.id,
      })
    );

    // Add the Card to the Block Area
    $card.insertAfter($deck.find("[data-drag-and-drop-freezed]"));
  });

  // ////////////////////////////////
  // ADD THEMED ARTICLE ELEMENT MODAL
  // Listen Add Element click events on the Modal
  $("body").on("click.home.modal", ".home-page #add-article-card button.btn-primary", function (event) {
    // Get the Modal & the Slider
    const $modal = $(event.currentTarget).parents(".modal");
    const $deck = $(`.home-page [data-home-area=une] #${$modal.data("uneElementId")} .slider-container .row`);
    // Create the Card
    const $card = $("<div/>", { class: "col" });

    switch ($modal.data("document").endpoint) {
      case "/episodes":
        $card.append(
          $.renderTemplate(`home${$modal.data("type")}Episode`, {
            episode: $modal.data("document"),
            serie: $modal.data("document").obsession._id,
          })
        );
        break;
      case "/podcasts":
        $card.append(
          $.renderTemplate(`home${$modal.data("type")}Podcast`, {
            podcast: $modal.data("document"),
            serie: $modal.data("document").programme._id,
          })
        );
        break;
    }

    // Add the Card to the Block Area
    $card.insertAfter($deck.find("[data-drag-and-drop-freezed]"));
  });

  // /////////////////
  // SHOW MODAL EVENTS
  // Listen Show event on Modals
  $("body").on(
    "show.bs.modal.home",
    [
      ".home-page #add-master-element",
      ".home-page #add-grid-element",
      ".home-page #add-serie-card",
      ".home-page #add-article-card",
    ].join(", "),
    function (event) {
      const $button = $(event.relatedTarget);
      const $modal = $(this);

      // Reset Data
      $modal.removeData("document");
      $modal.removeData("linkedDocument");
      $modal.removeData("uneElementId");

      // Check if we have an id data on the Clicked Button
      if ($button.closest("[data-une-element-area]").length > 0) {
        // Add the Deck Id data
        $modal.data("uneElementId", $button.closest("[data-une-element-area]").attr("id"));
      }
      // Check if there is a Filter on the Clicked Button
      if ($button.data("ljFilter") !== undefined) {
        // Add the Filter data on the input
        $modal.find(".js-autocomplete").data("filters", $button.data("ljFilter"));
      }
      // Check if there is an Endpoint on the Clicked Button
      if ($button.data("ljEndpoint") !== undefined) {
        // Add the Endpoint data on the input
        $modal.find(".js-autocomplete").data("endpoint", $button.data("ljEndpoint"));
      }
      // Check if there is an element Type on the Clicked Button
      if ($button.data("ljType") !== undefined) {
        // Add the Endpoint data on the input
        $modal.data("type", $button.data("ljType"));
      } else {
        // Add the Endpoint data on the input
        $modal.data("type", "Cards");
      }

      // Reset Autocomplete Inputs
      $modal.find(".js-autocomplete").val("");
    }
  );

  // Set focus on the first input
  $("body").on("shown.bs.modal", ".home-page .modal", function (event) {
    $(event.target).find("input:first-of-type").not("[name=une-element]").focus();
  });

  // Listen Show event on "Add Une Element" Modal
  $("body").on("show.bs.modal.home", ".home-page #add-une-element", function (event) {
    // Check if it's a modal with data-container (Une Elements)
    const $button = $(event.relatedTarget);
    const $modal = $(this);
    const $container = $modal.find("[data-container=" + $button.data("uneElementKind") + "]");

    // Hide all containers
    $modal.find("div[data-container]").addClass("d-none");
    // Display the right container
    if ($button.data("uneElementKind") !== undefined) {
      $container.removeClass("d-none");
    }

    // Clear radio container inputs
    $('input[name="une-element"]').prop("checked", false);
    // Select the right radio container input
    if ($button.data("uneElementKind") !== undefined) {
      $modal.find("input[id=" + $button.data("uneElementKind") + "]").prop("checked", true);
    }

    // Disable the Add button
    if ($button.data("uneElementKind") !== "une-themed-slider") {
      $modal.find("button.btn-primary").prop("disabled", true);
    }

    // Clear all text inputs
    $modal.find("input[type='text']").val("");
    // Clear all datas
    $modal.removeData("uneElementId");
    $modal.removeData("uneElementPosition");
    $modal.find("[data-container]").removeData("document");

    // Check if the Add button is between two elements
    if ($button.attr("data-placeholder-id") !== undefined) {
      $modal.data("uneElementPosition", $button.attr("data-placeholder-id"));
    }

    // Check if it's an edit
    if ($button.hasClass("btn-edit")) {
      // Add the element's id to the modal
      $modal.data("uneElementId", $button.attr("data-une-element-id"));

      // Check if there is a document
      if ($button.data("uneElementDocument") !== undefined) {
        // Add the document to the container
        $.ajax({
          url: $button.data("uneElementEndpoint") + "/card/" + $button.data("uneElementDocument"),
          method: "GET",
          dataType: "json",
          headers: {
            Accept: "application/json",
          },
        }).done(function (data) {
          // Trigger an Event with the Selected Element
          $container.find("input.js-autocomplete").val(data.title);
          // Add the document data to the container
          $container.data("document", data);
          // Enable the Add button
          $modal.find("button.btn-primary").prop("disabled", false);
        });
      }

      // Check the type of elements
      if ($button.data("uneElementModalTab") !== undefined) {
        $container.find("input[id=" + $button.data("uneElementModalTab")).prop("checked", true);
      }

      // Check the title of the element
      if ($button.data("uneElementTitle") !== undefined) {
        $container.find("input[name='slider-title'").val($button.data("uneElementTitle"));
      }

      // Check the number of elements
      if ($button.data("uneElementLength") !== undefined) {
        $container.find("input[name='slider-elements-length'").val($button.data("uneElementLength"));
      }
    }
  });

  // //////////////////////
  // RADIO BUTTONS CONTAINER DISPLAY ON MODAL
  // Listen Click Event on Buttons in Add Une Element Modal
  $("body").on("click.button.une", ".home-page #add-une-element input[name='une-element']", function (event) {
    const $input = $(event.currentTarget);
    const $modal = $(event.currentTarget).closest(".modal");

    // Hide all of the data-container elements
    $modal.find("div[data-container]").addClass("d-none");
    // Display the right container
    $modal.find("[data-container=" + $input.attr("id") + "]").removeClass("d-none");

    // Disable/Enable the Add button
    if (
      $input.attr("id") === "une-themed-slider" ||
      $modal.find(".js-element-container").not(".d-none").data("document") !== undefined
    ) {
      // Enable the Add button
      $modal.find("button.btn-primary").prop("disabled", false);
    } else {
      // Disable the Add button
      $modal.find("button.btn-primary").prop("disabled", true);
    }
  });

  // //////////////////////////
  // DROPDOWN ITEM SELECT EVENT
  // Listen Click Event on the selected .dropdown-item
  $("body").on(
    "autocomplete.selected.home-modals",
    [
      ".home-page #add-master-element .js-element .js-autocomplete",
      ".home-page #add-grid-element .js-element .js-autocomplete",
      ".home-page #add-serie-card .js-element .js-autocomplete",
      ".home-page #add-article-card .js-element .js-autocomplete",
      ".home-page #add-une-element [data-container='une-cover-element'] .js-autocomplete",
      ".home-page #add-une-element [data-container='une-serie-slider'] .js-autocomplete",
    ].join(", "),
    function (event, $selected) {
      // Get the Modal
      const $modal = $(event.currentTarget).parents(".modal");
      const $input = $selected.closest(".dropdown").find(".js-autocomplete");

      $.ajax({
        url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
        method: "GET",
        dataType: "json",
        headers: {
          Accept: "application/json",
        },
      }).done(function (data) {
        switch ($modal.attr("id")) {
          case "add-une-element":
            // Save the Data
            $modal.find(".js-element-container").not(".d-none").data("document", data);

            // Enable the input
            $modal.find("button.btn-primary").prop("disabled", false);
            break;
          default:
            // Save the Data
            $modal.data("document", data);
        }
        // Update the Autocomplete Input
        $input.val(data.title);
      });
    }
  );

  // ///////////////////////////
  // ADD ELEMENT EVENTS ON MODAL
  // Listen Add Element on Modal Click Events
  $("body").on(
    "click.home.modal",
    [
      ".home-page #add-master-element button.btn-primary",
      ".home-page #add-grid-element button.btn-primary",
      ".home-page #add-serie-card button.btn-primary",
    ].join(", "),
    function (event) {
      // Get the Modal
      const $modal = $(event.currentTarget).parents(".modal");
      let $deck;

      // Get the Deck
      switch ($modal.data("area")) {
        case "cards":
          // Check if we have a Deck Id data to identify the correct slider
          if ($modal.data("uneElementId") !== undefined) {
            $deck = $(".home-page [data-home-area=une] #" + $modal.data("uneElementId") + " .slider-container .row");
          }
          break;
        default:
          $deck = $(".home-page [data-home-area=" + $modal.data("area") + "] .js-deck");
      }

      // Create the Card
      const $card = $("<div/>", { class: "col" });
      switch ($modal.data("document").endpoint) {
        case "/episodes":
          $card.append(
            $.renderTemplate("home" + $modal.data("type") + "Episode", {
              episode: $modal.data("document"),
            })
          );
          break;
        case "/podcasts":
          $card.append(
            $.renderTemplate("home" + $modal.data("type") + "Podcast", {
              podcast: $modal.data("document"),
            })
          );
          break;
        case "/medias/people":
          $card.append(
            $.renderTemplate("home" + $modal.data("type") + "People", {
              people: $modal.data("document"),
              obsession: $modal.data("obsession"),
              metaperson: $modal.data("metaperson"),
            })
          );
          break;
        case "/obsessions":
          $card.append(
            $.renderTemplate("home" + $modal.data("type") + "Media", {
              media: $modal.data("document"),
              search: $modal.data("area") === "master",
            })
          );
          break;
        case "/programmes":
          $card.append(
            $.renderTemplate("home" + $modal.data("type") + "Media", {
              media: $modal.data("document"),
              search: $modal.data("area") === "master",
            })
          );
          break;
      }

      // Add the Card to the Block Area
      switch ($modal.attr("id")) {
        case "add-serie-card":
          $card.insertAfter($deck.find("[data-drag-and-drop-freezed]"));
          break;
        default:
          if ($modal.data("type") === "Lists") {
            $deck.append($card);
          } else {
            $deck.prepend($card);
          }

          // Balance elements between dragula containers
          if ($deck.data("multipleContainerLength") !== undefined) {
            balanceDragulaMultipleContainers();
          }
      }

      // Trigger Event to the Body
      $("body").trigger("ajaxFormProcessed", true);
    }
  );

  // Helper to fetch Elements for a Serie Slider
  async function fetchUneSerieSliderData($container) {
    const serie = $container.data("document");
    const number = parseInt($container.find("input[name='slider-elements-length']").val());

    // Check the kind of slider
    let kind;
    if (serie.modelName === "Programme") {
      kind = "PodcastsProgrammeSlider";
    } else {
      kind =
        $container.find("input[name='serie-slider-elements']:checked").attr("id") === "elements-people"
          ? "PeopleObsessionSlider"
          : "EpisodesObsessionSlider";
    }

    // Get the elements & the endpoint
    let endpoint;
    let elements;
    switch (kind) {
      case "PeopleObsessionSlider":
        elements = $container.data("document").people;
        endpoint = "/medias/people";
        break;
      case "EpisodesObsessionSlider":
        elements = $container.data("document").episodes;
        endpoint = "/episodes";
        break;
      case "PodcastsProgrammeSlider":
        elements = $container.data("document").podcasts;
        endpoint = "/podcasts";
        break;
      default:
        throw new Error(`Can't fetch data for this kind of slider : ${kind}`);
    }

    // Check if we need to reverse the order
    if ($container.find("input[name='elements-order']:checked").attr("id") === "desc") {
      elements.reverse();
    }

    // Slice the array
    elements = elements.slice(0, number);

    // Fetch elements
    for (let index = 0; index < elements.length; index++) {
      elements[index] = await $.ajax({ url: `${endpoint}/card/${elements[index]}`, method: "GET", dataType: "json" });
    }

    // Filter episodes/podcasts without a date
    if (kind === "EpisodesObsessionSlider" || kind === "PodcastsProgrammeSlider") {
      elements = elements.filter((value) => value.date !== undefined);
    }

    // Return the slider's data
    return { serie: serie, kind: kind, elements: elements };
  }

  // Listen Add Element on "Add Une Element" Modal Click Events
  $("body").on("click.home.modal", ".home-page #add-une-element button.btn-primary", async function (event) {
    // Variables
    const $modal = $(event.currentTarget).parents(".modal");
    const $container = $modal.find(".js-element-container").not(".d-none");
    const $deck = $("div[data-home-area='une']");

    // Create the Card
    let $card;
    switch ($container.attr("data-container")) {
      case "une-serie-slider":
        $card = $.renderTemplate("homeSlidersSerie", {
          slider: await fetchUneSerieSliderData($container),
        });
        break;
      case "une-themed-slider":
        $card = $.renderTemplate("homeSlidersThemed", {
          slider: {
            title: $container.find("input[name='slider-title'").val(),
            kind: $container.find("input[name='themed-slider-elements']:checked").attr("data-kind"),
            elements: [],
            modalTab: $container.find("input[name='themed-slider-elements']:checked").attr("id"),
          },
        });
        break;
      case "une-cover-element":
        $card = $.renderTemplate(
          `homeCovers${$container.data("document").endpoint === "/episodes" ? "Episode" : "Podcast"}`,
          {
            cover: $container.data("document"),
          }
        );
        break;
      default:
        throw new Error(`Can't create an element for the container : ${$container.attr("data-container")}`);
    }

    // Check if we have to edit or add an element
    if ($modal.data("uneElementId") !== undefined) {
      // Edit the element with
      $("#" + $modal.data("uneElementId")).replaceWith($card);
    } else if ($modal.data("uneElementPosition") !== undefined) {
      $(".home-page #" + $modal.data("uneElementPosition")).after($card);

      // Scroll to the Element
      $([document.documentElement, document.body]).animate(
        {
          scrollTop:
            $("#" + $modal.data("uneElementPosition")).offset().top +
            ($("#" + $modal.data("uneElementPosition")).outerHeight() - 200),
        },
        0
      );
    } else {
      // Add the Card to the Block Area
      $deck.prepend($card);
    }

    // Reset $modal datas
    $modal.removeData("document");
  });

  // ///////////////////
  // SWITCH MASTER EVENT
  $("body").on("change.home.master", "select[name='master-format']", async function (event) {
    // Variables
    const $master = $("[data-home-area='master']");
    const selected = $(event.target).val();
    const curKind = $master.find(".card-master input[name='kind']").val();
    const elements = [];
    let cardUrl;
    let cardIntro;

    // Get the new kind & fluid state
    const newKind = selected.indexOf("Fluid") > -1 ? selected.replace("Fluid", "") : selected;
    const newFluid = selected.indexOf("Fluid") > -1;

    // Keep or move some informations from/in the right column
    if (curKind !== "MasterSimple" && curKind === newKind) {
      // Check if we have an obsession/programme card
      if ($master.find(".right-column .media-card").length > 0) {
        const endpoint =
          $(".right-column .media-card").find("input[name='model']").val() === "Obsession"
            ? "/obsessions"
            : "/programmes";
        cardUrl = `${endpoint}/card/${$master.find(".right-column .media-card").find("input[name='document']").val()}`;
        cardIntro = $master.find(".right-column .edith-editor").getEdithEditorContent();
      }

      $master.find(".right-column .right-elements .col").each(function (idx, element) {
        // Get the element
        elements.push({
          model: $(element).find("input[name='model']").val(),
          document: $(element).find("input[name='document']").val(),
        });
      });
    } else if (curKind === "MasterDoubleArticles" && curKind !== newKind) {
      const rightColElements = $master.find(".right-column .right-elements .col");

      // Keep the right column elements in the grid
      $("[data-home-area=grid] [data-drag-and-drop-container]").first().prepend(rightColElements);
      balanceDragulaMultipleContainers();
    } else if (curKind !== "MasterDoubleArticles" && newKind === "MasterDoubleArticles") {
      // Keep the first 3 elements from the grid
      const $grid = $("[data-home-area=grid] [data-drag-and-drop-container]").first();
      const $gridElements = $grid.find(".col").slice(0, 3);
      $gridElements.each(function (idx, element) {
        // Get the element
        elements.push({
          model: $(element).find("input[name='model']").val(),
          document: $(element).find("input[name='document']").val(),
        });
      });

      // Remove elements from the grid
      $gridElements.remove();
      balanceDragulaMultipleContainers();
    }

    // If we have elements in the right column
    if (elements.length > 0) {
      // Map the elements
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];

        // Get the endpoint
        let endpoint;
        switch (element.model) {
          case "Episode":
            endpoint = "/episodes";
            break;
          case "Podcast":
            endpoint = "/podcasts";
            break;
          case "People":
            endpoint = "/medias/people";
        }

        // Get the card for the document
        elements[index].document = await $.ajax({
          url: `${endpoint}/card/${element.document}`,
          method: "GET",
          dataType: "json",
        });
      }
    }

    // Get the Master
    const masterModel = $master.find(".card-master input[name='model']").val();
    const masterDoc = $master.find(".card-master input[name='document']").val();
    const masterEndpoint = masterModel === "Podcast" ? "/podcasts" : "/episodes";
    let master;
    switch (newKind) {
      case "MasterSimple":
        master = {
          kind: newKind,
          fluid: newFluid,
          model: masterModel,
          document: await $.ajax({ url: `${masterEndpoint}/card/${masterDoc}`, method: "GET", dataType: "json" }),
        };
        break;
      case "MasterDoubleArticles":
        master = {
          kind: newKind,
          fluid: newFluid,
          model: masterModel,
          document: await $.ajax({ url: `${masterEndpoint}/card/${masterDoc}`, method: "GET", dataType: "json" }),
          rightColumn: {
            elements: elements,
          },
        };
        break;
      case "MasterDoubleObsession":
        cardUrl = cardUrl !== undefined ? cardUrl : "/obsessions/placeholder";
        master = {
          kind: newKind,
          fluid: newFluid,
          model: masterModel,
          document: await $.ajax({ url: `${masterEndpoint}/card/${masterDoc}`, method: "GET", dataType: "json" }),
          rightColumn: {
            intro: cardIntro !== undefined ? cardIntro : "",
            card: await $.ajax({ url: cardUrl, method: "GET", dataType: "json" }),
            elements: elements,
          },
        };
        break;
      case "MasterDoubleProgramme":
        cardUrl = cardUrl !== undefined ? cardUrl : "/programmes/placeholder";
        master = {
          kind: newKind,
          fluid: newFluid,
          model: masterModel,
          document: await $.ajax({ url: `${masterEndpoint}/card/${masterDoc}`, method: "GET", dataType: "json" }),
          rightColumn: {
            intro: cardIntro !== undefined ? cardIntro : "",
            card: await $.ajax({ url: cardUrl, method: "GET", dataType: "json" }),
            elements: elements,
          },
        };
        break;
    }

    // Keep the selected snake position and title size
    master.snake = $master.find("select[name=master-snake]").val();
    master.titleSize = $master.find("select[name=master-title-size]").val();

    // Insert the template
    $master.empty();
    $master.append($.renderTemplate("homeFormsMaster", { master: master }));

    // Check if we need to update Dragula Containers
    if ($master.find(".right-column").length > 0) {
      const $drake = $("[data-multiple-container=home]");
      $drake.updateDragulaContainers();
    }

    // Check if there is an Edith editor
    if ($master.find(".right-column .edith-editor").length > 0) {
      // Initialize the Edith editor
      $master.find(".right-column .edith-editor").initializeEdithEditor();
    }

    // Check if it's a Master Simple Maxi
    if (this.value === "MasterSimpleFluid") {
      // Initialize ColorPicker
      $master.find(".js-color-picker").initializeColorPickers();

      // Set the episode's obsession color to the Obsession option
      $master
        .find(".js-color-picker")
        .colorpicker(
          "setValue",
          master.document.programme ? master.document.programme.color.main : master.document.obsession.color.main
        );
    }
  });

  // ///////////////////////////////////
  // AUTOCOMPLETE REPLACE ELEMENT EVENTS
  // Listen to Master Autocomplete
  $("body").on(
    "autocomplete.selected.home-master",
    ".home-page [data-home-area=master] .card-master .js-autocomplete",
    function (event, $selected) {
      const element = event.currentTarget;
      const $master = $(element).parents(".home-page [data-home-area=master]");

      // Get & Save the Element Card
      $.ajax({
        url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
        method: "GET",
        dataType: "json",
      }).done(function (data) {
        // Replace the Current Master
        $(".home-page [data-home-area=master] .card-master").replaceWith(
          $.renderTemplate(`homeMaster${$selected.data("endpoint") === "/episodes" ? "Episode" : "Podcast"}`, {
            master: {
              kind: $master.find("input[name='kind']").val(),
              fluid: $master.find("input[name='fluid']").val() === "true",
              document: data,
              snake: $master.find("select[name='master-snake']").val(),
              titleSize: $master.find("select[name='master-title-size']").val(),
            },
          })
        );

        // Check if it's a Master Simple Fluid
        if (
          $master.find("input[name='kind']").val() === "MasterSimple" &&
          $master.find("input[name='fluid']").val() === "true"
        ) {
          // Initialize ColorPicker
          $master.find(".js-color-picker").initializeColorPickers();

          // Set the episode's obsession color to the Obsession option
          $master
            .find(".js-color-picker")
            .colorpicker("setValue", data.programme ? data.programme.color.main : data.obsession.color.main);
        }
      });
    }
  );

  // Listen to Obsession Autocomplete
  $("body").on(
    "autocomplete.selected.home-card",
    ".home-page [data-home-area=master] .right-column .media-card .js-autocomplete",
    function (event, $selected) {
      const $master = $(".home-page [data-home-area=master]");

      // Get & Save the Element Card
      $.ajax({
        url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
        method: "GET",
        dataType: "json",
      }).done(function (data) {
        // Replace the Current Card on Master Right
        $(".home-page [data-home-area=master] .right-column").replaceWith(
          $.renderTemplate("homeMasterRightcolumn", {
            kind: $master.find(".card-master input[name='kind']").val(),
            rightColumn: {
              intro: "",
              card: data,
              elements: [],
            },
          })
        );

        // Initialize the Edith editor
        $master.find(".right-column .edith-editor").initializeEdithEditor();
      });
    }
  );

  // Listen to Cover Autocomplete
  $("body").on("autocomplete.selected.home-cover", ".home-page .cover .js-autocomplete", function (event, $selected) {
    const element = event.currentTarget;

    // Get & Save the Element Card
    $.ajax({
      url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
      method: "GET",
      dataType: "json",
    }).done(function (data) {
      // Replace the Current Cover
      $(element)
        .closest(".cover")
        .replaceWith(
          $.renderTemplate("homeCoversEpisode", {
            cover: data,
          })
        );
    });
  });

  // ///////////////////////////////////
  // LIVE ELEMENT EVENTS
  // Live
  $("body").on("change.home-live", ".home-page #liveactivation", function (event) {
    const $button = $(event.currentTarget);

    // Check if the live is activated
    if ($button.is(":checked")) {
      $(".home-page #master-live-group").removeClass("d-none");
    } else {
      $(".home-page #master-live-group").addClass("d-none");
    }
  });

  $("body").on(
    "change.home-live-platform",
    ".home-page #master-live-group select[name=master-live-platform]",
    function (event) {
      const $select = $(event.currentTarget);

      // Update the URL start
      const $selected = $select.find(":selected");
      $(".home-page #master-live-group .js-live-url-start").text($selected.data("startUrl"));
    }
  );

  // ///////////////
  // SAVE HOME EVENT
  // Listen Click Events on #save-btn Button
  $("body").on("click.home", ".home-page #save-btn", function (event) {
    // Prevent default
    event.preventDefault();

    // Variables
    const $form = $(".home-page form");
    const home = {
      master: {},
      une: [],
      body: [],
    };

    // Iterate through Home Areas
    $(".home-page form [data-home-area]").each(function (idxA, area) {
      // Get the Area
      const $area = $(area);
      const areaName = $area.data("homeArea");

      // Check the Area
      switch (areaName) {
        case "master":
          // Add Area Inputs
          home.master.kind = $area.find("input[name='kind']").val();
          home.master.fluid = $area.find("input[name='fluid']").val();
          home.master.model = $area.find("input[name='model']").val();
          home.master.document = $area.find("input[name='document']").val();
          home.master.snake = $area.find("[name='master-snake']").val();
          home.master.titleSize = $area.find("[name='master-title-size']").val();

          // Add the menu color
          if ($area.find("#master-color-group").is(":visible")) {
            home.master.menu = $area.find("[name='master-color-input']").val();
          }

          // Add a live entry if it's activated
          if ($area.find("#master-live-group").is(":visible")) {
            home.master.live = {
              platform: $area.find("[name='master-live-platform']").val(),
              videoId: $area.find("[name='master-live-videoId']").val(),
              from: $area.find("[name='master-live-from']").val(),
              to: $area.find("[name='master-live-to']").val(),
            };
          }

          // Add Right Column Inputs
          if (home.master.kind !== "MasterSimple") {
            // Get the Right Column Elements
            home.master.rightColumn = { elements: [] };
            $area.find(".right-column .right-elements .card").each(function (idx, element) {
              home.master.rightColumn.elements.push({
                model: $(element).find("input[name='model']").val(),
                document: $(element).find("input[name='document']").val(),
              });
            });

            // Check if there is an Obsession/Programme
            if ($area.find(".right-column .media-card").length > 0) {
              // Add the Obsession/Programme card to the Right Column
              home.master.rightColumn.intro = $area.find(".right-column .edith-editor").getEdithEditorContent();
              home.master.rightColumn.card = $area.find(".right-column .media-card input[name='document']").val();
            }
          }
          break;
        case "grid":
          $area.find(".card").each(function (idx, element) {
            home.une.push({
              model: $(element).find("input[name='model']").val(),
              document: $(element).find("input[name='document']").val(),
            });
          });
          break;
        case "une":
          $area.find("[data-une-element-area]").each(function (idx, element) {
            const elements = [];
            // Get the elements
            $(element)
              .find(".card-small")
              .each(function (index, card) {
                if ($(element).find("input[name='kind']").val() === "MetapersonsThemedSlider") {
                  elements.push({
                    obsession: $(card).find("input[name='obsession']").val(),
                    people: $(card).find("input[name='document']").val(),
                    metaperson: $(card).find("input[name='metaperson']").val(),
                  });
                } else if ($(element).find("input[name='kind']").val() === "SeriesThemedSlider") {
                  elements.push({
                    model: $(card).find("input[name='model']").val(),
                    document: $(card).find("input[name='document']").val(),
                  });
                } else if ($(element).find("input[name='kind']").val() === "ArticlesThemedSlider") {
                  elements.push({
                    article: {
                      model: $(card).find("input[name='model']").val(),
                      document: $(card).find("input[name='document']").val(),
                    },
                    serie: {
                      model: $(card).find("input[name='model']").val() === "Episode" ? "Obsession" : "Programme",
                      document: $(card).find("input[name='serie']").val(),
                    },
                  });
                } else {
                  elements.push($(card).find("input[name='document']").val());
                }
              });

            switch ($(element).data("une-element-area")) {
              case "CoverEpisode":
                home.body.push({
                  kind: $(element).find("input[name='kind']").val(),
                  cover: $(element).find("input[name='cover']").val(),
                });
                break;
              case "CoverPodcast":
                home.body.push({
                  kind: $(element).find("input[name='kind']").val(),
                  cover: $(element).find("input[name='cover']").val(),
                });
                break;
              case "SeriesThemedSlider":
                home.body.push({
                  kind: $(element).find("input[name='kind']").val(),
                  title: $(element).find("input[name='slider-title']").val(),
                  elements: elements,
                });
                break;
              case "MetapersonsThemedSlider":
                home.body.push({
                  kind: $(element).find("input[name='kind']").val(),
                  title: $(element).find("input[name='slider-title']").val(),
                  elements: elements,
                });
                break;
              case "ArticlesThemedSlider":
                home.body.push({
                  kind: $(element).find("input[name='kind']").val(),
                  title: $(element).find("input[name='slider-title']").val(),
                  elements: elements,
                });
                break;
              default:
                home.body.push({
                  kind: $(element).find("input[name='kind']").val(),
                  serie: $(element).find("input[name='serie']").val(),
                  elements: elements,
                });
            }
          });
          break;
      }
    });

    // Send the Home Form
    $.ajaxFormProcessPromise(
      $.ajax({
        method: $form.attr("method").toUpperCase(),
        url: $form.attr("action"),
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify(home),
      })
    );
  });
});
