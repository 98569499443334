
      import pug from 'pug-runtime';

      function welcomeBirthdays(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (birthdays) {
      pug_html = pug_html + "\u003Cform class=\"list-group\"\u003E\u003Cdiv class=\"list-group-item list-group-item-info d-flex\"\u003E\u003Cspan class=\"fs-4\"\u003E" + (pug.escape(null == (pug_interp = birthdays.current) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"ms-auto ps-3\"\u003E\u003Cbutton" + (" class=\"btn btn-primary js-birthdays-move\""+" type=\"button\""+pug.attr("data-birthdays-target", birthdays.prev, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-backward-step\"\u003E\u003C\u002Fi\u003E&nbsp;Jour&nbsp;précédent\u003C\u002Fbutton\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"ps-3\"\u003E\u003Cbutton" + (" class=\"btn btn-primary js-birthdays-move\""+" type=\"button\""+pug.attr("data-birthdays-target", birthdays.next, true, false)) + "\u003EJour&nbsp;suivant&nbsp;\u003Ci class=\"fa-solid fa-forward-step\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
// iterate birthdays.pages
;(function(){
  var $$obj = birthdays.pages;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var person = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"list-group-item justify-content-start align-items-center d-flex\"\u003E\u003Cspan\u003E\u003Cspan class=\"fst-italic\"\u003E" + (pug.escape(null == (pug_interp = person.date) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ch5 class=\"m-0\"\u003E" + (null == (pug_interp = person.name) ? "" : pug_interp) + "\u003C\u002Fh5\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"ms-auto ps-3\"\u003E\u003Ca" + (" class=\"btn btn-info\""+pug.attr("href", person.url, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-link\"\u003E\u003C\u002Fi\u003E&nbsp;URL\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"ps-3\"\u003E\u003Ca" + (" class=\"btn btn-info\""+pug.attr("href", person.image, true, false)+pug.attr("download", true, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-download\"\u003E\u003C\u002Fi\u003E&nbsp;Header\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var person = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"list-group-item justify-content-start align-items-center d-flex\"\u003E\u003Cspan\u003E\u003Cspan class=\"fst-italic\"\u003E" + (pug.escape(null == (pug_interp = person.date) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Ch5 class=\"m-0\"\u003E" + (null == (pug_interp = person.name) ? "" : pug_interp) + "\u003C\u002Fh5\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"ms-auto ps-3\"\u003E\u003Ca" + (" class=\"btn btn-info\""+pug.attr("href", person.url, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-link\"\u003E\u003C\u002Fi\u003E&nbsp;URL\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"ps-3\"\u003E\u003Ca" + (" class=\"btn btn-info\""+pug.attr("href", person.image, true, false)+pug.attr("download", true, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-download\"\u003E\u003C\u002Fi\u003E&nbsp;Header\u003C\u002Fa\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fform\u003E";
    }.call(this, "birthdays" in locals_for_with ?
        locals_for_with.birthdays :
        typeof birthdays !== 'undefined' ? birthdays : undefined));
    ;;return pug_html;}

      export default welcomeBirthdays;
    