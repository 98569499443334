
      import pug from 'pug-runtime';

      function mediasFormsReusability(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (expiration, formatdate, right) {
      pug_html = pug_html + "\u003Cdiv class=\"card my-2\"\u003E\u003Cdiv class=\"card-body d-flex p-0\"\u003E\u003Cdiv class=\"w-100 p-3\"\u003E\u003Cdiv class=\"row align-items-center m-0\"\u003E\u003Cselect class=\"form-control\" name=\"reusability\"\u003E\u003Coption" + (" value=\"4\""+pug.attr("selected", right == 4 ? true : false, true, false)) + "\u003EÉdito\u003C\u002Foption\u003E\u003Coption" + (" value=\"2\""+pug.attr("selected", right == 2 ? true : false, true, false)) + "\u003ESponso\u003C\u002Foption\u003E\u003Coption" + (" value=\"1\""+pug.attr("selected", right == 1 ? true : false, true, false)) + "\u003EPrint\u003C\u002Foption\u003E\u003C\u002Fselect\u003E\u003Cdiv class=\"invalid-feedback\"\u003EUn même droit ne peut être sélectionné qu’une seule fois.\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (expiration) {
pug_html = pug_html + "\u003Cdiv class=\"js-reusability-expiration row align-items-center m-0 mt-2\"\u003E\u003Clabel class=\"mb-1 p-0\"\u003EJusqu’au&nbsp;:\u003C\u002Flabel\u003E\u003Cinput" + (" class=\"expiration\""+" type=\"hidden\""+pug.attr("name", "expirations[" + right + "]", true, false)+pug.attr("value", expiration, true, false)) + "\u002F\u003E\u003Cdiv class=\"input-group date p-0\"\u003E\u003Cinput" + (" class=\"form-control js-datetime-picker\""+" type=\"text\" name=\"date-datetimepicker\" autocomplete=\"off\""+pug.attr("data-datetimepicker-target-input", "expirations[" + right + "]", true, false)+pug.attr("data-datetimepicker-default", formatdate(expiration), true, false)) + "\u002F\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fa-solid fa-calendar-days\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003Cbutton class=\"js-toggle-expiration btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"row m-0 mt-2\"\u003E\u003Cbutton class=\"js-toggle-expiration btn btn-primary\" type=\"button\"\u003EAjouter une date d’expiration\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv\u003E\u003Cbutton class=\"js-remove-reusability btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "expiration" in locals_for_with ?
        locals_for_with.expiration :
        typeof expiration !== 'undefined' ? expiration : undefined, "formatdate" in locals_for_with ?
        locals_for_with.formatdate :
        typeof formatdate !== 'undefined' ? formatdate : undefined, "right" in locals_for_with ?
        locals_for_with.right :
        typeof right !== 'undefined' ? right : undefined));
    ;;return pug_html;}

      export default mediasFormsReusability;
    