import $ from "jquery";

// Listen Submit Events on Form Elements
$("body").on("click.resetButton", "button.js-reset-button", function (event) {
  // Prevent Default Actions
  event.preventDefault();
  event.stopPropagation();

  // Get the Input Group
  const $btn = $(event.currentTarget);
  const $group = $($btn.parents(".input-group").get(0));
  const $input = $group.find("input");

  // Reset the Input Value
  $input.val($btn.data("resetValue"));
});
