import $ from "jquery";
import * as bootstrap from "bootstrap";

// Listen
$(document.body).on("shown.bs.tab", '[data-bs-toggle="pill"][data-bs-target]', function (event) {
  // Update the hash
  window.location.hash = $(event.target).attr("data-bs-target");

  // Scroll to the Top of the Page
  $("html, body").animate(
    {
      scrollTop: 0,
    },
    500,
    "swing"
  );
});

// Check if there is a hash
if (window.location.hash && window.location.hash !== "") {
  // Check if there is a tab with this href
  const $tab = $('[data-bs-toggle="pill"][data-bs-target="' + window.location.hash + '"]');
  if ($tab.length > 0) {
    // Show the corresponding pane
    bootstrap.Tab.getOrCreateInstance($tab.get(0)).show();
  }
}
