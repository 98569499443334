
      import pug from 'pug-runtime';

      function episodesEditorLivePostMenu(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (editable, episode) {
      pug_html = pug_html + "\u003Cdiv class=\"row my-4 align-items-center\" data-ajax-form-key=\"live-post-menu\"\u003E\u003Cdiv class=\"col-sm-4 col-lg-auto\"\u003EÉditer un post&nbsp;:\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"dropdown col-auto\""+pug.attr("data-episode", episode.id, true, false)) + "\u003E\u003Cbutton class=\"btn btn-outline-primary dropdown-toggle\" data-bs-toggle=\"dropdown\" role=\"button\" aria-expanded=\"false\"\u003EChoisir un post\u003C\u002Fbutton\u003E\u003Cdiv class=\"dropdown-menu\"\u003E\u003Cbutton class=\"dropdown-item d-flex flex-row align-items-center\" type=\"button\" data-post=\"intro\"\u003EIntroduction\u003C\u002Fbutton\u003E";
// iterate episode.posts
;(function(){
  var $$obj = episode.posts;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var post = $$obj[pug_index0];
pug_html = pug_html + "\u003Cbutton" + (" class=\"dropdown-item d-flex flex-row align-items-center\""+" type=\"button\""+pug.attr("data-post", post.postId, true, false)) + "\u003E" + (null == (pug_interp = post.titleTime) ? "" : pug_interp) + " - " + (null == (pug_interp = post.title) ? "" : pug_interp) + "\u003C\u002Fbutton\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var post = $$obj[pug_index0];
pug_html = pug_html + "\u003Cbutton" + (" class=\"dropdown-item d-flex flex-row align-items-center\""+" type=\"button\""+pug.attr("data-post", post.postId, true, false)) + "\u003E" + (null == (pug_interp = post.titleTime) ? "" : pug_interp) + " - " + (null == (pug_interp = post.title) ? "" : pug_interp) + "\u003C\u002Fbutton\u003E";
    }
  }
}).call(this);

if ((!episode.inProgress || episode.outro !== undefined)) {
pug_html = pug_html + "\u003Cbutton class=\"dropdown-item d-flex flex-row align-items-center\" type=\"button\" data-post=\"outro\"\u003EConclusion\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (editable) {
pug_html = pug_html + "\u003Cdiv class=\"col-sm-12 col-lg-auto\"\u003E";
if (episode.inProgress) {
pug_html = pug_html + "\u003Cbutton" + (" class=\"btn btn-primary\""+" id=\"add-live-post\" type=\"button\""+pug.attr("data-episode", episode.id, true, false)) + "\u003E+ Nouveau post\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "editable" in locals_for_with ?
        locals_for_with.editable :
        typeof editable !== 'undefined' ? editable : undefined, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined));
    ;;return pug_html;}

      export default episodesEditorLivePostMenu;
    