import $ from "jquery";

$(function () {
  // Listen Change Events on Select Elements
  $("body").on("autocomplete.selected.serie", ".series-page .js-autocomplete", function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");
    const endpoint = $selected.data("endpoint");

    // Get the Card
    $.ajax({
      url: endpoint + "/card/" + id,
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
    }).done(function (card) {
      // Append the Card to the List
      $selected.parents(".card").find(".card-deck-wrapper > .row").append(card);
    });
  });

  // Listen Click Events on .btn-danger Buttons on Card
  $("body").on("click.serie", ".series-page .card .btn-danger", function (event) {
    // Remove the Card From the DOM
    $($(event.currentTarget).parents(".card").get(0)).parent().remove();
  });
});
