
      import pug from 'pug-runtime';

      function homeListsPeople(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (people, resimg) {
      pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"People\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"row g-0\"\u003E\u003Cdiv class=\"col-md-4\"\u003E\u003Cdiv class=\"card-btn p-1\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"h-100 w-100 rounded-start\""+" style=\"object-fit: cover\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-8\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", people.endpoint + "/" + people.id, true, false)) + "\u003E\u003Ch6 class=\"card-title\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "people" in locals_for_with ?
        locals_for_with.people :
        typeof people !== 'undefined' ? people : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined));
    ;;return pug_html;}

      export default homeListsPeople;
    