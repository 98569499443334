import $ from "jquery";

$(function () {
  $("form.no-enter").on("keydown.formNoEnter", function (event) {
    if (event.which === 13 && $(event.target).is("input, select, button")) {
      event.preventDefault();
      return false;
    }
  });
});
