import $ from "jquery";

$(function () {
  // ///////////////////////////////////////////////////////////////////////////////////
  // SPECIAL PAGES                                                                    //
  // ///////////////////////////////////////////////////////////////////////////////////
  // Activate the CodeMirror for Special Pages
  if ($(".marketing-page #editor .codemirror-holder").length > 0) {
    // Create a CodeMirror Object from the Textarea
    $(".marketing-page #editor .codemirror-holder textarea").createCodeMirrorFromTextArea();
  }

  // Listen Click on Delete Buttons in List Groups
  $("body").on("click.marketing", ".marketing-page .js-add-episode .list-group-item .btn-danger", function (event) {
    // Remove the Element
    $(event.currentTarget).parents(".list-group-item").remove();
  });

  // Listen Episodes Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.marketing",
    ".marketing-page .js-add-episode .js-autocomplete",
    function (event, $selected) {
      // Get the Episode Area
      const $area = $selected.parents(".js-add-episode");

      // Get the Episode JSON
      $.ajax({
        url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
        method: "GET",
        dataType: "json",
      }).done(function (data) {
        // Add a List Group Item for the Selected Element
        $("<div>", { class: "list-group-item" })
          .append($("<input>", { type: "hidden", name: $area.data("inputName"), value: data.id }))
          .append(
            $("<div>", { class: "d-flex w-100 justify-content-between" })
              .append(
                $("<div>", { class: "flex-grow-1 pe-4" })
                  .append(
                    $("<div>", { class: "d-flex w-100 justify-content-between" })
                      .append($('<h5 class="mb-1">' + data.title + "</h5>"))
                      .append($("<small>" + $.renderUtils.printDate(data.date) + "</small>"))
                  )
                  .append($('<p class="mb-1">' + data.subtitle + "</p>"))
              )
              .append($("<button>", { class: "btn btn-danger d-inline my-auto px-3", text: "Enlever" }))
          )
          .appendTo($area.find(".list-group"));
      });
    }
  );

  // ///////////////////////////////////////////////////////////////////////////////////
  // MARKETING DOCUMENTS                                                              //
  // ///////////////////////////////////////////////////////////////////////////////////
  // Listen Changes on Search for Marketing Documents
  $("body").on(
    "input.marketing",
    [
      ".marketing-page #marketing-documents-list .js-documents-search-title",
      ".marketing-page #marketing-documents-list .js-documents-search-type input[type='radio']",
    ].join(", "),
    function () {
      // Get the Marketing Documents area
      const $area = $(".marketing-page #marketing-documents-list");
      const $list = $area.find(".js-documents-list-container");

      // Get search data
      const title = $area.find(".js-documents-search-title").val();
      const type = $area.find(".js-documents-search-type input:checked").val();

      // Don’t send the request in particular cases
      if (title.length >= 1 && title.length < 3 && (!type || type === "")) {
        return;
      }

      // Get the Episode JSON
      $.ajax({
        url: "/marketing/document/search",
        method: "GET",
        dataType: "html",
        headers: {
          Accept: "text/html",
        },
        data: {
          title: title,
          type: type,
        },
      }).done(function (list) {
        // Append the List
        $list.replaceWith(list);
      });
    }
  );

  // ///////////////////////////////////////////////////////////////////////////////////
  // IMAGES                                                                           //
  // ///////////////////////////////////////////////////////////////////////////////////
  // Listen Changes on Search for Marketing Images
  $("body").on(
    "input.marketing",
    [
      ".marketing-page #marketing-images-list .js-images-search-title",
      ".marketing-page #marketing-images-list .js-images-search-credit",
      ".marketing-page #marketing-images-list .js-images-search-reusability input[name=reusability]",
    ].join(", "),
    function () {
      // Get the Marketing Images area
      const $area = $(".marketing-page #marketing-images-list");
      const $list = $area.find(".js-images-list-container");
      const $checked = $area.find("input[name=reusability]:checked");

      // Get search data
      const title = $area.find(".js-images-search-title").val();
      const credit = $area.find(".js-images-search-credit").val();
      const reusabilities = $checked
        .map(function (idx, el) {
          return $(el).val();
        })
        .get();

      // Don’t send the request in particular cases : small search query in title & credit fields
      if ((title.length >= 1 && title.length < 3) || (credit.length >= 1 && credit.length < 3)) {
        return;
      }

      // Get the Episode JSON
      $.ajax({
        url: "/marketing/image/search",
        method: "GET",
        dataType: "html",
        headers: {
          Accept: "text/html",
        },
        data: {
          title: title.length >= 3 ? title : undefined,
          credit: credit.length >= 3 ? credit : undefined,
          reusabilities: reusabilities,
        },
      }).done(function (list) {
        // Append the List
        $list.replaceWith(list);
      });
    }
  );

  // Listen Serie Autocomplete Selection Event on the Image search by serie
  $("body").on("click.marketing", ".marketing-page #marketing-images-by-serie .dropdown-item", function (event) {
    // Get the Search Area
    const $button = $(event.currentTarget);
    const $list = $button.parents("#marketing-images-by-serie").find(".js-images-list-container");

    // Get the Search Result
    $.ajax({
      url: "/marketing/image/search/serie",
      method: "GET",
      dataType: "html",
      headers: {
        Accept: "text/html",
      },
      data: {
        model: $button.data("model"),
        id: $button.data("id"),
      },
    }).done(function (result) {
      // Replace the List
      $list.replaceWith(result);
    });
  });

  // ///////////////////////////////////////////////////////////////////////////////////
  // IN THE PRESS                                                                     //
  // ///////////////////////////////////////////////////////////////////////////////////
  // Listen Websites Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.inthepress",
    ".marketing-page .js-autocomplete[name=websitepicker]",
    function (event, $selected) {
      // Get Selected Element Information
      const $dropdown = $selected.parents(".dropdown");
      const $input = $dropdown.find("#website");
      const $searchbox = $("[name=websitepicker]");

      // Put the ID in the Input Field
      $input.val($selected.data("id"));
      $searchbox.val($selected.data("title"));
    }
  );
});
