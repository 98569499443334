import $ from "jquery";

$("body").on("click.lesjours.rewind", ".js-rewind-move", (e) => {
  const $btn = $(e.currentTarget);
  const $form = $btn.parents("form");

  $.ajax({
    url: `/accueil/rewind?date=${encodeURIComponent($btn.data("rewindTarget"))}`,
    method: "GET",
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  }).then((data) => {
    $form.replaceWith($.renderTemplate("welcomeRewind", data));
  });
});

$("body").on("click.lesjours.birthdays", ".js-birthdays-move", (e) => {
  const $btn = $(e.currentTarget);
  const $form = $btn.parents("form");

  $.ajax({
    url: `/accueil/birthdays?date=${encodeURIComponent($btn.data("birthdaysTarget"))}`,
    method: "GET",
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  }).then((data) => {
    $form.replaceWith($.renderTemplate("welcomeBirthdays", data));
  });
});
