
      import pug from 'pug-runtime';

      function homeFormsObsession(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (obsession, resimg, toTextOnly) {
      pug_html = pug_html + "\u003Cdiv class=\"card obsession-card card-tall\"\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E\u003Cdiv class=\"row row-center m-0\"\u003E";
if (obsession) {
pug_html = pug_html + "\u003Ca" + (" class=\"btn btn-primary w-auto\""+pug.attr("href", "/obsessions/" + obsession.id, true, false)) + "\u003E\u003Ci class=\"far fa-file-alt\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"dropdown w-auto\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fas fa-search\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003Cinput class=\"form-control js-autocomplete\" type=\"text\" data-endpoint=\"\u002Fobsessions\" autocomplete=\"off\"\u002F\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-w100\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (obsession) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(obsession.img, "840"), true, false)+pug.attr("alt", toTextOnly(obsession.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay\"\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = obsession.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003Cp class=\"card-text text-white\"\u003E" + (pug.escape(null == (pug_interp = obsession.episodes.length + ((obsession.episodes.length !== 1) ? " épisodes" : " épisode")) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "obsession" in locals_for_with ?
        locals_for_with.obsession :
        typeof obsession !== 'undefined' ? obsession : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeFormsObsession;
    