
      import pug from 'pug-runtime';

      function homeCardsQuoted(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (author, episode, printDate, text) {
      pug_html = pug_html + "\u003Cdiv class=\"card quoted-card card-small\"\u003E\u003Cinput type=\"hidden\" name=\"kind\" value=\"QuotedDoc\"\u002F\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"text\""+pug.attr("value", text, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"author\""+pug.attr("value", author, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E\u003Cbutton class=\"btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Ca" + (" class=\"btn btn-primary ms-2\""+pug.attr("href", "/episodes/" + episode.id, true, false)) + "\u003E\u003Ci class=\"far fa-file-alt\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-right\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body bg-info rounded\"\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = text) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = author) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "author" in locals_for_with ?
        locals_for_with.author :
        typeof author !== 'undefined' ? author : undefined, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "text" in locals_for_with ?
        locals_for_with.text :
        typeof text !== 'undefined' ? text : undefined));
    ;;return pug_html;}

      export default homeCardsQuoted;
    