import $ from "jquery";

$(function () {
  // Update Colors
  $(".programmes-page form select[name=color]").each(function (idx, el) {
    $(el).attr("style", $(el).find(":selected").attr("style"));
  });

  // Listen Change Events on Select Elements
  $("body").on("change.programme", ".programmes-page form select[name=color]", function (event) {
    // Get the Select
    const $select = $(event.currentTarget);

    // Update Colors
    $select.attr("style", $select.find(":selected").attr("style"));
  });

  // Listen Change Events on File Input Elements
  $("body").on("change.programme", ".programmes-page form input[type=file]", function (event) {
    // Get the Input, Form & Preview Elements
    const $input = $(event.currentTarget);
    const $preview = $input.parents("form").find($input.data("previewTarget"));
    const reader = new FileReader();

    // Test if there is a File
    if ($input.get(0).files.length > 0) {
      // Read the File
      reader.readAsDataURL($input.get(0).files[0]);

      // Load the Image inside the Preview
      reader.addEventListener(
        "load",
        function () {
          let $img = $preview.find("img");
          if ($img.length === 0) {
            $preview.empty();
            $preview.append($("<img>", { class: "rounded img-fluid mb-3", src: reader.result }));
          } else {
            $img.attr("src", reader.result);
          }
        },
        false
      );
    }
  });
  $("body").on(
    "click.programme",
    ".programmes-page form .js-programme-cover-img-preview .btn-danger",
    function (event) {
      // Prevent default
      event.preventDefault();
      event.stopPropagation();

      // Get the Preview Element
      const $preview = $(event.currentTarget).parents(".js-programme-cover-img-preview");

      // Replace the Preview with an empty Input
      $preview.empty();
      $preview.append($("<input>", { type: "hidden", name: "cover.img", value: "deleted" }));
    }
  );

  // Listen Change Events on Select Sort Options Input
  $("body").on("change.programme", ".programmes-page #programmes-list select.js-sort-programmes-by", function (event) {
    // Get Programmes
    $.ajax({
      url: "/programmes",
      method: "GET",
      data: { sortBy: $(event.target).val() },
    }).done(function (html) {
      // Replace the Programmes List
      $("#programmes-list .js-programmes-list").replaceWith(html);
    });
  });

  // Listen Add Media Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.programme",
    ".programmes-page .js-media-pane .js-autocomplete",
    function (event, $selected) {
      // Get Selected Element Information
      const id = $selected.data("id");
      const $pane = $selected.parents(".js-media-pane");
      const programme = $pane.data("programme");
      const mediatype = $pane.data("mediatype");

      // Call the Add Media URL
      $.ajax({
        url: "/programmes/" + programme + "/medias/" + mediatype,
        method: "POST",
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        data: { id: id },
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $selected.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Clicks on Media Delete Button
  $("body").on("click.programme", ".programmes-page .js-media-pane .media-card .btn-danger", function (event) {
    const $clicked = $(event.currentTarget);
    const $card = $clicked.parents(".media-card");
    const input = $card.find("input[name=media]");
    const id = input.val();
    const $pane = $clicked.parents(".js-media-pane");
    const programme = $pane.data("programme");
    const mediatype = $pane.data("mediatype");

    // Call the Delete Media URL
    $.ajax({
      url: "/programmes/" + programme + "/medias/" + mediatype + "/" + id,
      method: "DELETE",
    })
      .done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $clicked.parents(".tab-pane").attr("id");
        window.location.reload(true);
      })
      .fail(function (jqXHR) {
        // Check if we have an Error Message
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          // Show an Error Message
          $.notifyDanger({
            title: "Oups !",
            message: jqXHR.responseJSON.message,
          });
        }
      });
  });

  // Listen Drop Events on Media Containers
  $("body").on(
    "dragulaDrop.programme",
    ".programmes-page .js-media-pane .js-drag-and-drop-container",
    function (event, el, target) {
      const $dropped = $(el);
      const input = $dropped.find("input[name=media]");
      const id = input.val();
      const $siblings = $(target).children();
      const $pane = $dropped.parents(".js-media-pane");
      const programme = $pane.data("programme");
      const mediatype = $pane.data("mediatype");

      // Call the Update Media URL
      // We have to Remove 1 to the Position for the Dragula Mirror
      $.ajax({
        url: "/programmes/" + programme + "/medias/" + mediatype + "/" + id,
        method: "PUT",
        data: { position: $siblings.length - 1 - $dropped.index() - 1 },
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $dropped.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Clicks Events on Publish/Unpublish Media Buttons
  $("body").on(
    "click.programme",
    [
      ".programmes-page .js-media-pane .media-card .card-btn-hover .btn-success",
      ".programmes-page .js-media-pane .media-card .card-btn-hover .btn-warning",
    ].join(", "),
    function (event) {
      const $clicked = $(event.currentTarget);
      const input = $clicked.parents(".media-card").find("input[name=media]");
      const id = input.val();
      const $pane = $clicked.parents(".js-media-pane");
      const programme = $pane.data("programme");
      const mediatype = $pane.data("mediatype");

      // Call the Update Media URL
      $.ajax({
        url: "/programmes/" + programme + "/medias/" + mediatype + "/" + id,
        method: "PUT",
        data: { publish: $clicked.hasClass("btn-success") },
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $clicked.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Drop Events on Podcasts Containers
  $("body").on(
    "dragulaDrop.programme",
    ".programmes-page #podcasts .js-drag-and-drop-container",
    function (event, el, target) {
      const $dropped = $(el);
      const input = $dropped.find("input[name=podcast]");
      const id = input.val();
      const $siblings = $(target).children();
      const $pane = $dropped.parents("#podcasts");
      const programme = $pane.data("programme");

      // Call the Update Media URL
      // We have to Remove 1 to the Position for the Dragula Mirror
      $.ajaxFormProcessPromise(
        $.ajax({
          url: "/programmes/" + programme + "/podcasts/" + id,
          method: "PUT",
          data: { position: $siblings.length - 1 - $dropped.index() - 1 },
        })
      );
    }
  );

  // Listen Clicks on Board Dropdown Elements to Fix Background Issue
  $("body").on("click.programme", ".programmes-page .nav a[role=tab]", function (event) {
    const $clicked = $(event.currentTarget);
    const $nav = $clicked.parents(".nav");

    // Remove .active class on Nav Elements
    $nav.find(".active").removeClass("active");
  });

  // Listen Add Author Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.programme",
    ".programmes-page #add-author .js-autocomplete",
    function (event, $selected) {
      // Get Selected Element Information
      const id = $selected.data("id");
      const text = $selected.data("text");
      const $dropdown = $selected.parents(".dropdown");
      const $input = $dropdown.find(".js-autocomplete");

      // Update the Autocomplete
      $input.data("author", id);
      $input.val(text);
    }
  );
  // Update the Author Value before Any Submit
  $("body").on("click.programme", ".programmes-page #add-author form .btn-primary", function (event) {
    // Get the jQuery Input
    const $clicked = $(event.currentTarget);
    const $form = $clicked.parents("form");
    const $author = $form.find("input[name=author]");

    // Check if the Input has a data-author attribute
    if ($author.data("author")) {
      // Replace the Value by it's data-author attribute
      $author.val($author.data("author"));
    }

    // Submit the Form
    $form.get(0).submit();
  });
});
