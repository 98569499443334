import { replaceAllText } from "@lesjoursfr/browser-tools";
import { Edith, EdithButton } from "@lesjoursfr/edith";
import $ from "jquery";

const editorDataKey = "edithEditor";
const eventsNamespace = "lesjours.edith";

function doMagicWithEdith($editor) {
  // Normalize the content
  $editor.normalize();

  // Add missing non-breaking spaces
  replaceAllText($editor.get(0), /«\s/g, '«<span class="edith-nbsp" contenteditable="false">¶</span>', false);
  replaceAllText(
    $editor.get(0),
    /\s(»|\?|!|;|:)/g,
    '<span class="edith-nbsp" contenteditable="false">¶</span>$1',
    false
  );
  replaceAllText(
    $editor.get(0),
    /(\d)(\s(\d{3}))+/g,
    function (match) {
      return match.replace(/\s/g, '<span class="edith-nbsp" contenteditable="false">¶</span>');
    },
    false
  );
  replaceAllText($editor.get(0), /«([^\s])/g, '«<span class="edith-nbsp" contenteditable="false">¶</span>$1', false);
  replaceAllText(
    $editor.get(0),
    /([^\s])(»|\?|!|;|:(?!\/\/))/g,
    '$1<span class="edith-nbsp" contenteditable="false">¶</span>$2',
    false
  );

  // Replace english simple quotes by french ones
  replaceAllText($editor.get(0), /'/g, "’");

  // Try to fix Bad Position for Open/Closing Quotes
  $editor.html(
    $editor
      .html()
      .replace(
        /«(\s|<span class="edith-nbsp" contenteditable="false">¶<\/span>)*<i>(\s|<span class="edith-nbsp" contenteditable="false">¶<\/span>)*/g,
        '<i>«<span class="edith-nbsp" contenteditable="false">¶</span>'
      )
      .replace(
        /(\s|<span class="edith-nbsp" contenteditable="false">¶<\/span>)*<\/i>(\s|<span class="edith-nbsp" contenteditable="false">¶<\/span>)*»/g,
        '<span class="edith-nbsp" contenteditable="false">¶</span>»</i>'
      )
  );

  // Normalize Paragraphs
  $editor.normalize();
}

// Count and display remaining characters
function countRemainingChars(event) {
  const { $edith, $display, maxLength } = event.data;
  const length = $edith.find(".edith-visual").text().length;

  if (length <= maxLength) {
    $display
      .removeClass("text-danger")
      .addClass("text-success")
      .html(maxLength - length);
  } else {
    $display
      .removeClass("text-success")
      .addClass("text-danger")
      .html(maxLength - length);
  }
}

// Function to Initialize an Edith Editor
function initializeEdithEditor(domElement) {
  // Get jQuery Element
  const $editor = $(domElement);
  const editorData = $editor.data();
  const $input = $editor.find("input");
  const $edith = $editor.find(".edith-element");

  // Check if the Editor has already been Initialized
  if ($edith.data(editorDataKey)) {
    return;
  }

  // Count and display the number of remaining characters
  if ($editor.hasClass("js-remaining-chars")) {
    const data = {
      $edith: $edith,
      $display: $edith.parents(".js-field-group").find(".form-text > span"),
      maxLength: parseInt($edith.parents(".edith-editor").data("remaining-max"), 10),
    };

    // At initialization
    $edith.on("edith-initialized", data, countRemainingChars);

    // On change
    $edith.on("keyup", data, countRemainingChars);
  }

  // Initialize the Editor
  $edith.data(
    editorDataKey,
    new Edith($edith.get(0), {
      height: editorData.edithHeight ? parseInt(editorData.edithHeight, 10) : 200,
      resizable: editorData.edithResizable ?? false,
      toolbar:
        editorData.edithToolbarMode === "nolinks"
          ? [
              ["style", ["bold", "italic", "underline", "strikethrough", "subscript", "superscript", "nbsp", "clear"]],
              ["insert", ["wand", "screwdriver"]],
              ["misc", ["codeview"]],
            ]
          : [
              ["style", ["bold", "italic", "underline", "strikethrough", "subscript", "superscript", "nbsp", "clear"]],
              ["insert", ["link", "wand", "screwdriver"]],
              ["misc", ["codeview"]],
            ],
      buttons: {
        wand: (context) =>
          new EdithButton(context, {
            icon: "fa-solid fa-wand-magic",
            title: "Baguette magique",
            onclick: () => {
              doMagicWithEdith($($edith.data(editorDataKey).editor.getVisualEditorElement()));
            },
          }),
        screwdriver: (context) =>
          new EdithButton(context, {
            icon: "fa-solid fa-screwdriver",
            title: "Groupe insécable",
            onclick: (ctx) => {
              ctx.editor.wrapInsideTag("nobr");
            },
          }),
      },
      initialContent: $input.val(),
    })
  );

  // Listen Submit Events on the Parent
  $editor.parents("form").on(`click.${eventsNamespace}`, "[type=submit]", function () {
    // Update the Input
    $input.val($edith.data(editorDataKey).getContent());
  });

  // Set an Initialized Flag
  $editor.data("editorInitialized", true);
}

// Function to Destroy an Edith Editor
function destroyEdithEditor(domElement) {
  // Get jQuery Element
  const $editor = $(domElement);
  const $edith = $editor.find(".edith-element");

  // Destroy the Edith Editor
  if ($edith.data(editorDataKey)) {
    $edith.data(editorDataKey).destroy();
    $edith.removeData(editorDataKey);
  }

  // Empty the Input
  $editor.find("input[type=hidden]").val("");
}

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// JQuery functions
$.fn.getEdithEditorContent = function () {
  return this.eq(0).find(".edith-element").data(editorDataKey).getContent();
};
$.fn.setEdithEditorContent = function (html) {
  return this.eq(0).find(".edith-element").data(editorDataKey).setContent(html);
};
$.fn.initializeEdithEditor = function () {
  return this.each((idx, el) => initializeEdithEditor(el));
};
$.fn.destroyEdithEditor = function () {
  return this.each((idx, el) => destroyEdithEditor(el));
};
$.fn.saveEdithEditorInValue = function () {
  return this.each((idx, el) => {
    const $editor = $(el);
    $editor.find("input").val($editor.find(".edith-element").data(editorDataKey).getContent());
  });
};

// Listen ajaxFormProcessed Events
$("body").on(`ajaxFormProcessed.${eventsNamespace}`, function () {
  // Initialize Edith Elements
  $(".edith-editor").each((idx, el) => initializeEdithEditor(el));
});

// Initialize on page load
$(function () {
  // Initialize Edith Elements
  $(".edith-editor").each((idx, el) => initializeEdithEditor(el));
});
