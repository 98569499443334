
      import pug from 'pug-runtime';

      function modalsEmojis(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (categories) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade\" id=\"emojis-modal\" role=\"dialog\" aria-labelledby=\"emojis-modal-label\" aria-hidden=\"true\"\u003E\u003Cdiv class=\"modal-dialog modal-lg\" role=\"document\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Ch4 class=\"modal-title\" id=\"emojis-modal-label\"\u003EListe des emojis\u003C\u002Fh4\u003E\u003Cbutton class=\"btn-close\" type=\"button\" data-bs-dismiss=\"modal\" aria-label=\"Fermer\"\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Cdiv class=\"container-fluid\"\u003E\u003Cdiv class=\"row\"\u003E";
// iterate categories
;(function(){
  var $$obj = categories;
  if ('number' == typeof $$obj.length) {
      for (var category = 0, $$l = $$obj.length; category < $$l; category++) {
        var emojis = $$obj[category];
pug_html = pug_html + "\u003Cdiv class=\"col-12 mt-3\"\u003E\u003Cp\u003E" + (pug.escape(null == (pug_interp = category) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cdiv class=\"emoji-category\"\u003E";
// iterate emojis
;(function(){
  var $$obj = emojis;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var emoji = $$obj[pug_index1];
pug_html = pug_html + "\u003Cspan" + (pug.attr("data-to-clipboard", emoji.char, true, false)) + "\u003E" + (pug.escape(null == (pug_interp = emoji.char) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var emoji = $$obj[pug_index1];
pug_html = pug_html + "\u003Cspan" + (pug.attr("data-to-clipboard", emoji.char, true, false)) + "\u003E" + (pug.escape(null == (pug_interp = emoji.char) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var category in $$obj) {
      $$l++;
      var emojis = $$obj[category];
pug_html = pug_html + "\u003Cdiv class=\"col-12 mt-3\"\u003E\u003Cp\u003E" + (pug.escape(null == (pug_interp = category) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003Cdiv class=\"emoji-category\"\u003E";
// iterate emojis
;(function(){
  var $$obj = emojis;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var emoji = $$obj[pug_index2];
pug_html = pug_html + "\u003Cspan" + (pug.attr("data-to-clipboard", emoji.char, true, false)) + "\u003E" + (pug.escape(null == (pug_interp = emoji.char) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var emoji = $$obj[pug_index2];
pug_html = pug_html + "\u003Cspan" + (pug.attr("data-to-clipboard", emoji.char, true, false)) + "\u003E" + (pug.escape(null == (pug_interp = emoji.char) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "categories" in locals_for_with ?
        locals_for_with.categories :
        typeof categories !== 'undefined' ? categories : undefined));
    ;;return pug_html;}

      export default modalsEmojis;
    