
      import pug from 'pug-runtime';

      function marketingFormsObsession(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (obsession, resimg, toTextOnly) {
      pug_html = pug_html + "\u003Cdiv class=\"py-1\"\u003E\u003Cdiv class=\"list-group-item list-group-item-action justify-content-start align-items-center d-flex\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsessions\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"col-1\""+pug.attr("src", resimg(obsession.img, "480"), true, false)+pug.attr("alt", toTextOnly(obsession.title), true, false)) + "\u002F\u003E" + (pug.escape(null == (pug_interp = obsession.title) ? "" : pug_interp)) + "\u003Cbutton class=\"btn btn-danger ms-auto\"\u003ESupprimer\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "obsession" in locals_for_with ?
        locals_for_with.obsession :
        typeof obsession !== 'undefined' ? obsession : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default marketingFormsObsession;
    