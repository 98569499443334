
      import pug from 'pug-runtime';

      function homeCardsSound(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (episode, getMIMEType, printDate, sound) {
      pug_html = pug_html + "\u003Cdiv class=\"card quoted-card card-small\"\u003E\u003Cinput type=\"hidden\" name=\"kind\" value=\"SoundDoc\"\u002F\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"sound\""+pug.attr("value", sound.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E\u003Cbutton class=\"btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Ca" + (" class=\"btn btn-primary ms-2\""+pug.attr("href", "/episodes/" + episode.id, true, false)) + "\u003E\u003Ci class=\"far fa-file-alt\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-right\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body bg-info rounded\"\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = sound.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003Cfigure class=\"mt-2\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate sound.files
;(function(){
  var $$obj = sound.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var audio = $$obj[pug_index0];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index0];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "getMIMEType" in locals_for_with ?
        locals_for_with.getMIMEType :
        typeof getMIMEType !== 'undefined' ? getMIMEType : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "sound" in locals_for_with ?
        locals_for_with.sound :
        typeof sound !== 'undefined' ? sound : undefined));
    ;;return pug_html;}

      export default homeCardsSound;
    