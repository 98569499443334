
      import pug from 'pug-runtime';

      function siteCardsSerie(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (media, resimg, slider) {
      pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small rounded\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"model\""+pug.attr("value", media.modelName, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", media._id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid rounded-top\""+pug.attr("src", media.img ? resimg(media.img, "840") : media.files[0], true, false)+pug.attr("alt", media.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", media.endpoint + "/" + media.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "media" in locals_for_with ?
        locals_for_with.media :
        typeof media !== 'undefined' ? media : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "slider" in locals_for_with ?
        locals_for_with.slider :
        typeof slider !== 'undefined' ? slider : undefined));
    ;;return pug_html;}

      export default siteCardsSerie;
    