
      import pug from 'pug-runtime';

      function homeListsPodcast(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (podcast, printDate, toTextOnly) {
      pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"d-flex flex-nowrap g-0\"\u003E\u003Cdiv class=\"pt-2 ps-2\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex-grow-1\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cdiv class=\"d-inline-flex align-items-center mb-2\"\u003E\u003Ci class=\"fa-regular fa-circle-play fa-2x text-primary me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", podcast.endpoint + "/" + podcast.id, true, false)) + "\u003E\u003Ch6 class=\"card-title mb-0\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003C\u002Fp\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Csmall class=\"text-muted\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-danger\"\u003ENon publié\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"flex mb-0\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary me-2\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "podcast" in locals_for_with ?
        locals_for_with.podcast :
        typeof podcast !== 'undefined' ? podcast : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeListsPodcast;
    