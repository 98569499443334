
      import pug from 'pug-runtime';

      function homeSlidersThemed(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (endpoint, episode, getMIMEType, getUniqueId, kind, media, metaperson, obsession, people, placeholderId, podcast, printDate, resimg, search, serie, slider, sliderId, target, toTextOnly) {
      kind = slider.kind;
sliderId = getUniqueId();
pug_html = pug_html + "\u003Cdiv" + (" class=\"slider my-5\""+pug.attr("id", sliderId, true, false)+pug.attr("data-une-element-area", kind, true, false)) + "\u003E\u003Cdiv class=\"d-flex align-items-start\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"kind\""+pug.attr("value", kind, true, false)) + "\u002F\u003E\u003Cdiv class=\"card position-relative p-4 flex-grow-1\" style=\"background-color: #f8f9fa; border-color: #6c757d\"\u003E\u003Cdiv class=\"input-group mb-4\"\u003E\u003Cinput" + (" class=\"form-control flex-grow-1 rounded-0 rounded-end\""+" type=\"text\" name=\"slider-title\""+pug.attr("value", slider.title, true, false)) + "\u002F\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"slider-container card-deck-wrapper\"\u003E\u003Cdiv class=\"row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xxl-5 g-3 js-deck js-drag-and-drop-container\" data-drag-and-drop-direction=\"horizontal\"\u003E\u003Cdiv class=\"col\" data-drag-and-drop-freezed=\"true\"\u003E";
switch (kind){
case "SeriesThemedSlider":
target = "serie";
  break;
case "MetapersonsThemedSlider":
target = "metaperson";
  break;
case "ArticlesThemedSlider":
target = "article";
  break;
}
pug_html = pug_html + "\u003Cdiv" + (" class=\"btn border-primary border-1 w-100 h-100 p-0 bg-transparent d-flex flex-column\""+" data-bs-toggle=\"modal\""+pug.attr("data-bs-target", "#add-" + target + "-card", true, false)) + "\u003E\u003Cdiv class=\"card-body flex-grow-1 w-100 d-flex justify-content-center align-items-center\"\u003E\u003Ci class=\"fa-solid fa-circle-plus fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-body w-100 flex-grow-0 d-flex justify-content-center p-2 border-top border-1 border-primary text-uppercase text-primary\"\u003E\u003Csmall\u003EAjouter un élément\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
// iterate slider.elements
;(function(){
  var $$obj = slider.elements;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var element = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
switch (kind){
case "SeriesThemedSlider":
media = element.document;
search = false;
endpoint = endpoint ? endpoint : '["/obsessions", "/programmes"]';
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small rounded\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"model\""+pug.attr("value", media.modelName, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", media._id, true, false)) + "\u002F\u003E";
if (media.img || (media.modelName === "SlideshowImage") || (media.modelName === "ComparisonImage")) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid rounded-top\""+pug.attr("src", resimg(media.img ? media.img : media.files[0], "840"), true, false)+pug.attr("alt", media.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E";
if (search) {
pug_html = pug_html + "\u003Cdiv class=\"search top-0 dropdown w-100\" role=\"group\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cinput" + (" class=\"form-control js-autocomplete\""+" type=\"text\" placeholder=\"Modifier le média à la une\""+pug.attr("data-endpoint", endpoint, true, false)+" autocomplete=\"off\"") + "\u002F\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fa-solid fa-magnifying-glass\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-w100\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", media.endpoint + "/" + media.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
else
if (media.files) {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info\"\u003E";
if (media.modelName === "MusicSoundtrack") {
pug_html = pug_html + "\u003Cfigure class=\"mt-2\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var audio = $$obj[pug_index1];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index1];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
}
else
if (media.modelName === "Document") {
pug_html = pug_html + "\u003Cdiv class=\"mt-2\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var doc = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var doc = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", media.endpoint + "/" + media.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
  break;
case "MetapersonsThemedSlider":
obsession = element.obsession;
people = element.people;
metaperson = element.metaperson;
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"metaperson\""+pug.attr("value", metaperson, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", people.endpoint + "/" + people.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "ArticlesThemedSlider":
if (element.article.model === "Episode") {
episode = element.article.document;
serie = element.serie.document._id;
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (episode.type !== "sans-image" && episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(episode.image.files ? episode.image.files[0] : episode.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", episode.endpoint + "/" + episode.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded min-height h-100\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (episode.quote.text) {
pug_html = pug_html + "\u003Cp class=\"mb-1 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote.text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cp class=\"mb-3 text-white\"\u003E" + (pug.escape(null == (pug_interp = episode.quote.author) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text mt-3 mb-3 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", episode.endpoint + "/" + episode.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.article.model === "Podcast") {
podcast = element.article.document;
serie = element.serie.document._id;
pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", podcast.endpoint + "/" + podcast.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var element = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
switch (kind){
case "SeriesThemedSlider":
media = element.document;
search = false;
endpoint = endpoint ? endpoint : '["/obsessions", "/programmes"]';
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small rounded\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"model\""+pug.attr("value", media.modelName, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", media._id, true, false)) + "\u002F\u003E";
if (media.img || (media.modelName === "SlideshowImage") || (media.modelName === "ComparisonImage")) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid rounded-top\""+pug.attr("src", resimg(media.img ? media.img : media.files[0], "840"), true, false)+pug.attr("alt", media.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E";
if (search) {
pug_html = pug_html + "\u003Cdiv class=\"search top-0 dropdown w-100\" role=\"group\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cinput" + (" class=\"form-control js-autocomplete\""+" type=\"text\" placeholder=\"Modifier le média à la une\""+pug.attr("data-endpoint", endpoint, true, false)+" autocomplete=\"off\"") + "\u002F\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fa-solid fa-magnifying-glass\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-w100\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", media.endpoint + "/" + media.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
else
if (media.files) {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info\"\u003E";
if (media.modelName === "MusicSoundtrack") {
pug_html = pug_html + "\u003Cfigure class=\"mt-2\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var audio = $$obj[pug_index3];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index3];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
}
else
if (media.modelName === "Document") {
pug_html = pug_html + "\u003Cdiv class=\"mt-2\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index4 = 0, $$l = $$obj.length; pug_index4 < $$l; pug_index4++) {
        var doc = $$obj[pug_index4];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index4 in $$obj) {
      $$l++;
      var doc = $$obj[pug_index4];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", media.endpoint + "/" + media.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
  break;
case "MetapersonsThemedSlider":
obsession = element.obsession;
people = element.people;
metaperson = element.metaperson;
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"metaperson\""+pug.attr("value", metaperson, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", people.endpoint + "/" + people.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "ArticlesThemedSlider":
if (element.article.model === "Episode") {
episode = element.article.document;
serie = element.serie.document._id;
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (episode.type !== "sans-image" && episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(episode.image.files ? episode.image.files[0] : episode.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", episode.endpoint + "/" + episode.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded min-height h-100\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (episode.quote.text) {
pug_html = pug_html + "\u003Cp class=\"mb-1 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote.text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cp class=\"mb-3 text-white\"\u003E" + (pug.escape(null == (pug_interp = episode.quote.author) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text mt-3 mb-3 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", episode.endpoint + "/" + episode.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.article.model === "Podcast") {
podcast = element.article.document;
serie = element.serie.document._id;
pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", podcast.endpoint + "/" + podcast.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btn-group-vertical rounded-end\"\u003E\u003Cbutton" + (" class=\"btn btn-danger rounded-0\""+" type=\"button\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-success btn-edit text-light rounded-0\""+" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-une-element-id", sliderId, true, false)+" data-une-element-kind=\"une-themed-slider\""+pug.attr("data-une-element-modal-tab", slider.modalTab, true, false)+pug.attr("data-une-element-title", slider.title, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-pen-to-square text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"up\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-arrow-up text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"down\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-arrow-down text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-deck-wrapper my-5 d-flex justify-content-center\"\u003E";
placeholderId = sliderId;
pug_html = pug_html + "\u003Cdiv" + (" class=\"btn add-button w-auto border-0\""+" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-placeholder-id", placeholderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-circle-plus fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "endpoint" in locals_for_with ?
        locals_for_with.endpoint :
        typeof endpoint !== 'undefined' ? endpoint : undefined, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "getMIMEType" in locals_for_with ?
        locals_for_with.getMIMEType :
        typeof getMIMEType !== 'undefined' ? getMIMEType : undefined, "getUniqueId" in locals_for_with ?
        locals_for_with.getUniqueId :
        typeof getUniqueId !== 'undefined' ? getUniqueId : undefined, "kind" in locals_for_with ?
        locals_for_with.kind :
        typeof kind !== 'undefined' ? kind : undefined, "media" in locals_for_with ?
        locals_for_with.media :
        typeof media !== 'undefined' ? media : undefined, "metaperson" in locals_for_with ?
        locals_for_with.metaperson :
        typeof metaperson !== 'undefined' ? metaperson : undefined, "obsession" in locals_for_with ?
        locals_for_with.obsession :
        typeof obsession !== 'undefined' ? obsession : undefined, "people" in locals_for_with ?
        locals_for_with.people :
        typeof people !== 'undefined' ? people : undefined, "placeholderId" in locals_for_with ?
        locals_for_with.placeholderId :
        typeof placeholderId !== 'undefined' ? placeholderId : undefined, "podcast" in locals_for_with ?
        locals_for_with.podcast :
        typeof podcast !== 'undefined' ? podcast : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "search" in locals_for_with ?
        locals_for_with.search :
        typeof search !== 'undefined' ? search : undefined, "serie" in locals_for_with ?
        locals_for_with.serie :
        typeof serie !== 'undefined' ? serie : undefined, "slider" in locals_for_with ?
        locals_for_with.slider :
        typeof slider !== 'undefined' ? slider : undefined, "sliderId" in locals_for_with ?
        locals_for_with.sliderId :
        typeof sliderId !== 'undefined' ? sliderId : undefined, "target" in locals_for_with ?
        locals_for_with.target :
        typeof target !== 'undefined' ? target : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeSlidersThemed;
    