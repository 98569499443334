import { Colorpicker } from "@lesjoursfr/colorpicker";
import ColorContrastChecker from "color-contrast-checker";
import $ from "jquery";

const colorPickerDataKey = "colorPicker";
const eventsNamespace = "lesjours.colorpicker";
const ccc = new ColorContrastChecker();

// Initialize a Color Picker
function initializeColorPicker(domElement) {
  // Check if the Color Picker has already been initialized
  if (domElement.colorpicker !== undefined) {
    return;
  }

  // jQuery Element
  const $colorPicker = $(domElement);

  // Initialize extensions
  const colorPickerExtensions = [];
  if ($colorPicker.data("defaultColors")) {
    // Get the default colors
    const colors = {};
    $colorPicker.data("defaultColors").forEach((value, idx) => {
      colors[`color${idx}`] = value;
    });

    // Add the swatches extension
    colorPickerExtensions.push({
      name: "swatches",
      options: {
        colors: colors,
        namesAsValues: false,
      },
    });
  }

  // Check if there is theme previews
  const $themePreviews = $colorPicker.find(".js-color-picker-text-preview");
  if ($themePreviews.length > 0) {
    $colorPicker.on(`colorpickerChange.${eventsNamespace} colorpickerCreate.${eventsNamespace}`, (e) => {
      const selectedColor = e.originalEvent.color?.toString();
      if (selectedColor === undefined) {
        return;
      }

      for (let $themePreview of $themePreviews) {
        $themePreview = $($themePreview);
        $themePreview.find(".js-color-picker-preview-color").css("color", selectedColor);
        if (ccc.isLevelAA($themePreview.data("previewBgColor"), selectedColor, $themePreview.data("previewFontSize"))) {
          $themePreview.find(".js-color-picker-preview-grade").removeClass("d-none");
        } else {
          $themePreview.find(".js-color-picker-preview-grade").addClass("d-none");
        }
      }
    });
  }

  // Initialize the Color Picker
  $colorPicker.data(
    colorPickerDataKey,
    new Colorpicker($colorPicker.get(0), {
      format: "hex",
      useAlpha: false,
      useHashPrefix: true,
      extensions: colorPickerExtensions,
      fallbackColor: "#000000",
    })
  );
}

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// JQuery functions
$.fn.initializeColorPickers = function () {
  return this.each((idx, el) => initializeColorPicker(el));
};

$.fn.colorpicker = function (colorPickerFn, ...fnArgs) {
  // Check if a Color Picker has been initialized on the first element
  const colorPickerInstance = this.eq(0).data(colorPickerDataKey);
  if (colorPickerInstance === undefined) {
    throw new Error(`This element doesn't have a Color Picker`);
  }

  // Restrict functions to getValue & setValue
  if (!["getValue", "setValue"].includes(colorPickerFn)) {
    throw new Error(`Unimplemented function : ${colorPickerFn}`);
  }

  // Call the function on the Color Picker
  return colorPickerInstance[colorPickerFn](...fnArgs);
};

// Listen ajaxFormProcessed Events
$("body").on(`ajaxFormProcessed.${eventsNamespace}`, function () {
  // Initialize Color Pickers Elements
  $(".js-color-picker").each((idx, el) => initializeColorPicker(el));
});

// Initialize on page load
$(function () {
  // Initialize Color Pickers Elements
  $(".js-color-picker").each((idx, el) => initializeColorPicker(el));
});
