
      import pug from 'pug-runtime';

      function modalsConfirmation(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (message, options, title) {
      pug_html = pug_html + "\u003Cdiv class=\"modal fade\" id=\"confirmation-modal\" role=\"dialog\" aria-labelledby=\"confirmation-modal-label\" aria-hidden=\"true\"\u003E\u003Cdiv class=\"modal-dialog\" role=\"document\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003E\u003Ch4 class=\"modal-title\" id=\"confirmation-modal-label\"\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E" + (pug.escape(null == (pug_interp = message) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"btn btn-primary\" type=\"button\"\u003E" + (pug.escape(null == (pug_interp = options.confirmBtnTxt) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-secondary\" type=\"button\" data-bs-dismiss=\"modal\" aria-label=\"Fermer\"\u003E" + (pug.escape(null == (pug_interp = options.cancelBtnTxt) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "message" in locals_for_with ?
        locals_for_with.message :
        typeof message !== 'undefined' ? message : undefined, "options" in locals_for_with ?
        locals_for_with.options :
        typeof options !== 'undefined' ? options : undefined, "title" in locals_for_with ?
        locals_for_with.title :
        typeof title !== 'undefined' ? title : undefined));
    ;;return pug_html;}

      export default modalsConfirmation;
    