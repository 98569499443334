import $ from "jquery";

// Show a Notification
function displayNotification(options) {
  // Create the Notification
  const container = $("<div>", { class: "alert alert-" + options.style + " text-center mb-0", role: "alert" });
  container.append(
    $("<p>", { class: "mb-0" })
      .append($("<strong>", { class: "me-2", text: options.title }))
      .append($("<i>", { class: options.icon + " me-2", "aria-hidden": "true" }))
      .append(options.message)
  );
  const notification = $("<div>", { class: "notification" });
  notification.append(container);

  // Show the Notification
  notification
    .hide()
    .appendTo("body")
    .fadeIn(500)
    .delay(4000)
    .fadeOut(500)
    .queue(function () {
      notification.remove();
    });
}

// Extend jQuery to Provide Method to display a Notification
$.notify = function (options) {
  displayNotification(options);
};

$.notifySuccess = function (options) {
  displayNotification($.extend({ style: "success", icon: "fa-regular fa-face-smile" }, options));
};

$.notifyInfo = function (options) {
  displayNotification($.extend({ style: "info", icon: "fa-solid fa-circle-info" }, options));
};

$.notifyWarning = function (options) {
  displayNotification($.extend({ style: "warning", icon: "fa-solid fa-circle-exclamation" }, options));
};

$.notifyDanger = function (options) {
  displayNotification($.extend({ style: "danger", icon: "fa-regular fa-face-frown" }, options));
};
