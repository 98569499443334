
      import pug from 'pug-runtime';

      function mediasFormsAuthor(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (media) {
      pug_html = pug_html + "\u003Cdiv class=\"list-group w-100 mb-3\"\u003E\u003Cdiv class=\"list-group-item justify-content-start align-items-center d-flex\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"author\""+pug.attr("value", media.author.id, true, false)) + "\u002F\u003E\u003Ch5 class=\"m-0\"\u003E" + (pug.escape(null == (pug_interp = media.author.name) ? "" : pug_interp)) + "\u003C\u002Fh5\u003E\u003Cbutton class=\"btn btn-danger ms-auto js-delete-media-author\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "media" in locals_for_with ?
        locals_for_with.media :
        typeof media !== 'undefined' ? media : undefined));
    ;;return pug_html;}

      export default mediasFormsAuthor;
    