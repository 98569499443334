
      import pug from 'pug-runtime';

      function newslettersCardsLink(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (link) {
      pug_html = pug_html + "\u003Cdiv class=\"card text-bg-secondary mb-3\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cdiv class=\"row flex-nowrap form-required\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ETitre&nbsp;:\u003C\u002Flabel\u003E\u003Cdiv class=\"col-auto flex-grow-1\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"30\" data-edith-resizable=\"true\" data-edith-toolbar-mode=\"nolinks\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"link.title\" autocomplete=\"off\""+pug.attr("value", link.title, true, false)) + "\u002F\u003E\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-auto\"\u003E\u003Cbutton class=\"btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row mt-3 form-required\"\u003E\u003Clabel class=\"col-sm-2 col-form-label\"\u003ETexte&nbsp;:\u003C\u002Flabel\u003E\u003Cdiv class=\"col-sm-10\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"200\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"link.text\" autocomplete=\"off\""+pug.attr("value", link.text, true, false)) + "\u002F\u003E\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "link" in locals_for_with ?
        locals_for_with.link :
        typeof link !== 'undefined' ? link : undefined));
    ;;return pug_html;}

      export default newslettersCardsLink;
    