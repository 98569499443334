
      import pug from 'pug-runtime';

      function partialsDropzoneMetadata(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (metadata) {
      pug_html = pug_html + "\u003Cdiv class=\"alert small m-0\"\u003E";
// iterate metadata.elements
;(function(){
  var $$obj = metadata.elements;
  if ('number' == typeof $$obj.length) {
      for (var key = 0, $$l = $$obj.length; key < $$l; key++) {
        var group = $$obj[key];
pug_html = pug_html + "\u003Cspan\u003E\u003Cstrong\u003EDonnées " + (pug.escape(null == (pug_interp = key) ? "" : pug_interp)) + "&nbsp;:\u003C\u002Fstrong\u003E\u003C\u002Fspan\u003E\u003Ctable\u003E";
// iterate group
;(function(){
  var $$obj = group;
  if ('number' == typeof $$obj.length) {
      for (var dataKey = 0, $$l = $$obj.length; dataKey < $$l; dataKey++) {
        var dataVal = $$obj[dataKey];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataKey) ? "" : pug_interp)) + "&nbsp;:\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataVal) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var dataKey in $$obj) {
      $$l++;
      var dataVal = $$obj[dataKey];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataKey) ? "" : pug_interp)) + "&nbsp;:\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataVal) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftable\u003E";
      }
  } else {
    var $$l = 0;
    for (var key in $$obj) {
      $$l++;
      var group = $$obj[key];
pug_html = pug_html + "\u003Cspan\u003E\u003Cstrong\u003EDonnées " + (pug.escape(null == (pug_interp = key) ? "" : pug_interp)) + "&nbsp;:\u003C\u002Fstrong\u003E\u003C\u002Fspan\u003E\u003Ctable\u003E";
// iterate group
;(function(){
  var $$obj = group;
  if ('number' == typeof $$obj.length) {
      for (var dataKey = 0, $$l = $$obj.length; dataKey < $$l; dataKey++) {
        var dataVal = $$obj[dataKey];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataKey) ? "" : pug_interp)) + "&nbsp;:\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataVal) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
      }
  } else {
    var $$l = 0;
    for (var dataKey in $$obj) {
      $$l++;
      var dataVal = $$obj[dataKey];
pug_html = pug_html + "\u003Ctr\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataKey) ? "" : pug_interp)) + "&nbsp;:\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = dataVal) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftable\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "metadata" in locals_for_with ?
        locals_for_with.metadata :
        typeof metadata !== 'undefined' ? metadata : undefined));
    ;;return pug_html;}

      export default partialsDropzoneMetadata;
    