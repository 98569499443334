import $ from "jquery";

// Extend jQuery to Provide Node Normalization Method
$.fn.normalize = function () {
  // Iterate over Node
  return this.each(function () {
    // Only process element nodes.
    if (this.nodeType === Node.ELEMENT_NODE) {
      // Normalize the node
      this.normalize();
    }
  });
};
