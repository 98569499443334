import $ from "jquery";

$.showConfirmationModal = function (title, message, onResponse, options) {
  // Use a boolean to prevent the callback being called twice
  let callbackIsCalled = false;

  // Add options text on buttons
  options = options || {};
  options.confirmBtnTxt = options.confirmBtnTxt || "Confirmer";
  options.cancelBtnTxt = options.cancelBtnTxt || "Annuler";

  // Create and append the Confirmation Modal
  const $modal = $($.renderTemplate("modalsConfirmation", { title: title, message: message, options: options }));
  $(document.body).append($modal);

  // Add Event Listeners
  $modal.find(".btn-primary").on("click", () => {
    // Call the callback
    callbackIsCalled = true;
    onResponse(true);

    // Hide the Confirmation Modal
    $modal.modal("hide");
  });

  $modal.on("hidden.bs.modal", () => {
    // Remove all Events Listeners
    $modal.find(".btn-primary").off();
    $modal.off();

    // Remove the Modal from the DOM
    $modal.remove();

    // Call the callback
    if (!callbackIsCalled) {
      onResponse(false);
    }
  });

  // Show the Confirmation Modal
  $modal.modal("show");
};
