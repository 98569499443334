
      import pug from 'pug-runtime';

      function partialsDropzoneDanger(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (metadata) {
      pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger m-0\"\u003E" + (null == (pug_interp = metadata.danger) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
    }.call(this, "metadata" in locals_for_with ?
        locals_for_with.metadata :
        typeof metadata !== 'undefined' ? metadata : undefined));
    ;;return pug_html;}

      export default partialsDropzoneDanger;
    