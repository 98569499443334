import $ from "jquery";

// ///////////////////////////////////////////////////////////////////////////////////
// INTERNAL FUNCTIONS                                                               //
// ///////////////////////////////////////////////////////////////////////////////////
// Parse the Response Content
function parseResponseContent(jqXHR) {
  // Check if it's a JSON
  const contentType = jqXHR.getResponseHeader("Content-Type");
  if (contentType !== null && contentType.startsWith("application/json;")) {
    return JSON.parse(jqXHR.responseText);
  }
  return { html: jqXHR.responseText };
}

// Show the Notification
function showAjaxNotification(success, data) {
  // Check the Status
  if (success) {
    $.notifySuccess({
      title: "Hey !",
      message: "Adrienne a bien enregistré les modifications.",
    });
  } else if (data && data.error) {
    $.notifyDanger({
      title: "Oups !",
      message: "Adrienne a rencontré une erreur lors de l’enregistrement&nbsp;: <i>" + data.error + "</i>",
    });
  } else {
    $.notifyDanger({
      title: "Oups !",
      message: "Adrienne a rencontré une erreur lors de l’enregistrement.",
    });
  }
}

// Hide/Show the Loading Modal
function displayAjaxLoadingModal(display) {
  $.loadingModal(display ? "show" : "hide");
}

// Process the Ajax Form Response
function processAjaxFormResponse(success, previousScrollPosition, data, $target) {
  // Trigger the Event on the form
  $target.trigger("ajaxFormProcess", data && data.json ? data.json : success);

  // Notify the User
  displayAjaxLoadingModal(false);
  showAjaxNotification(success, data);

  // If it’s a success and we have data
  if (success && data) {
    // If we have JSON code
    if (data.json) {
      // Trigger the Event on the form
      $target.trigger("ajaxFormProcessed", data.json);
      return;
    }

    // If we have HTML code
    if (data.html) {
      // Update the DOM Content
      let $first = null;
      $(data.html).each(function (idx, el) {
        // Replace Each Element in the DOM that have the Same Key
        const $el = $(el);
        $("[data-ajax-form-key=" + $el.data("ajaxFormKey") + "]").replaceWith($el);
        if ($first === null) {
          $first = $el;
        }
      });

      // Trigger the Event on the first element or the form
      ($first || $target).trigger("ajaxFormProcessed", success);

      // Reload the scroll position
      setTimeout(() => {
        $(window).scrollTop(previousScrollPosition);
      }, 10);
      return;
    }
  }

  // Trigger the Event on the form
  $target.trigger("ajaxFormProcessed", success);
}

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// Listen Submit Events on Form Elements
$("body").on("click.jsForm", "form.js-form [type=submit]", function (event) {
  // Get the Form
  const $btn = $(event.currentTarget);
  const $form = $($btn.parents("form").get(0));
  const action = $btn.attr("formaction") || $form.attr("action");
  const method = ($btn.attr("formmethod") || $form.attr("method")).toUpperCase();

  // Check if the Form is Valid
  if (typeof $form.get(0).checkValidity === "function" && !$form.get(0).checkValidity()) {
    // The form is invalid : Do nothing
    return;
  }

  // Prevent Default Actions
  event.preventDefault();
  event.stopPropagation();

  // Save the current scroll position
  const scrollPosition = $(window).scrollTop();

  // Show Loading Modal
  displayAjaxLoadingModal(true);

  // Send a jQuery Request
  $.ajax({
    url: action,
    method: method,
    data: $.inArray(method, ["GET", "DELETE"]) !== -1 ? undefined : new FormData($form.get(0)),
    processData: false,
    contentType: false,
  })
    .done(function (response) {
      // Process the Response
      processAjaxFormResponse(
        true,
        scrollPosition,
        typeof response === "object" ? { json: response } : { html: response },
        $form
      );
    })
    .fail(function (jqXHR) {
      // Process the Response
      processAjaxFormResponse(false, scrollPosition, parseResponseContent(jqXHR), $form);
    });

  // Check if the Form is in a Modal
  if ($form.parents(".modal").length > 0) {
    // Close the Modal
    $form.parents(".modal").modal("hide");
  }
});

// ///////////////////////////////////////////////////////////////////////////////////
// EXPORT FUNCTIONS                                                                 //
// ///////////////////////////////////////////////////////////////////////////////////
$.ajaxFormProcessPromise = function (promise, callback) {
  // Save the current scroll position
  const scrollPosition = $(window).scrollTop();

  // Show Loading Modal
  displayAjaxLoadingModal(true);

  // Process the Response of the Given Ajax Promise
  promise
    .done(function (response) {
      // Process the Response
      processAjaxFormResponse(
        true,
        scrollPosition,
        typeof response === "object" ? { json: response } : { html: response },
        $(window)
      );

      // Call the Callback with the Status
      if (typeof callback === "function") {
        callback(true);
      }
    })
    .fail(function (jqXHR) {
      // Process the Response
      processAjaxFormResponse(false, scrollPosition, parseResponseContent(jqXHR), $(window));

      // Call the Callback with the Status
      if (typeof callback === "function") {
        callback(false);
      }
    });
};
