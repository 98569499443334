import $ from "jquery";

// Create the Ajax Loading Modal
const $ajaxLoadingModal = (function () {
  // HTML Code
  const template =
    '<div class="modal" id="ajax-loading-modal" role="dialog" aria-labelledby="ajax-loading-modal-label" aria-hidden="true">' +
    '<div class="modal-dialog modal-lg" role="document">' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '<h4 class="modal-title" id="ajax-loading-modal-label">Merci de patienter&hellip;</h4>' +
    "</div>" +
    '<div class="modal-body">' +
    '<p class="m-0 d-flex align-items-center justify-content-center">' +
    '<span class="fa-3x me-3"><i class="fa-solid fa-spinner fa-spin-pulse" aria-hidden="true"></i></span>' +
    "Sauvegarde de vos modifications en cours&hellip;" +
    "</p>" +
    "</div>" +
    "</div>" +
    "</div>";

  // Return the Created Modal
  return $(template).appendTo("body");
})();

// Extend jQuery to Provide Method to display a Notification
$.loadingModal = function (action) {
  $ajaxLoadingModal.modal(action);
};
