import $ from "jquery";

$(function () {
  // Update Colors
  $(".obsessions-page form select[name=color]").each(function (idx, el) {
    $(el).attr("style", $(el).find(":selected").attr("style"));
  });

  // Listen Change Events on Select Elements
  $("body").on("change.obsession", ".obsessions-page form select[name=color]", function (event) {
    // Get the Select
    const $select = $(event.currentTarget);

    // Update Colors
    $select.attr("style", $select.find(":selected").attr("style"));
  });

  // Listen Change Events on File Input Elements
  $("body").on("change.obsession", ".obsessions-page form input[type=file]", function (event) {
    // Get the Input, Form & Preview Elements
    const $input = $(event.currentTarget);
    const $preview = $input.parents("form").find($input.data("previewTarget"));
    const reader = new FileReader();

    // Test if there is a File
    if ($input.get(0).files.length > 0) {
      // Read the File
      reader.readAsDataURL($input.get(0).files[0]);

      // Load the Image inside the Preview
      reader.addEventListener(
        "load",
        function () {
          let $img = $preview.find("img");
          if ($img.length === 0) {
            $preview.empty();
            $preview.append($("<img>", { class: "rounded img-fluid mb-3", src: reader.result }));
          } else {
            $img.attr("src", reader.result);
          }
        },
        false
      );
    }
  });
  $("body").on(
    "click.obsession",
    ".obsessions-page form .js-obsession-cover-img-preview .btn-danger",
    function (event) {
      // Prevent default
      event.preventDefault();
      event.stopPropagation();

      // Get the Preview Element
      const $preview = $(event.currentTarget).parents(".js-obsession-cover-img-preview");

      // Replace the Preview with an empty Input
      $preview.empty();
      $preview.append($("<input>", { type: "hidden", name: "cover.img", value: "deleted" }));
    }
  );

  // Listen Change Events on Select Sort Options Input
  $("body").on("change.obsession", ".obsessions-page #obsessions-list select.js-sort-obsessions-by", function (event) {
    // Get Obsessions
    $.ajax({
      url: "/obsessions",
      method: "GET",
      data: { sortBy: $(event.target).val() },
    }).done(function (html) {
      // Replace the Obsessions List
      $("#obsessions-list .js-obsessions-list").replaceWith(html);
    });
  });

  // Listen Add Media Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.obsession",
    ".obsessions-page .js-media-pane .js-autocomplete",
    function (event, $selected) {
      // Get Selected Element Information
      const id = $selected.data("id");
      const $pane = $selected.parents(".js-media-pane");
      const obsession = $pane.data("obsession");
      const mediatype = $pane.data("mediatype");

      // Call the Add Media URL
      $.ajax({
        url: "/obsessions/" + obsession + "/medias/" + mediatype,
        method: "POST",
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        data: { id: id },
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $selected.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Clicks on Media Delete Button
  $("body").on("click.obsession", ".obsessions-page .js-media-pane .media-card .btn-danger", function (event) {
    const $clicked = $(event.currentTarget);
    const $card = $clicked.parents(".media-card");
    const input = $card.find("input[name=media]");
    const id = input.val();
    const $pane = $clicked.parents(".js-media-pane");
    const obsession = $pane.data("obsession");
    const mediatype = $pane.data("mediatype");

    // Call the Delete Media URL
    $.ajax({
      url: "/obsessions/" + obsession + "/medias/" + mediatype + "/" + id,
      method: "DELETE",
    })
      .done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $clicked.parents(".tab-pane").attr("id");
        window.location.reload(true);
      })
      .fail(function (jqXHR) {
        // Check if we have an Error Message
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          // Show an Error Message
          $.notifyDanger({
            title: "Oups !",
            message: jqXHR.responseJSON.message,
          });
        }
      });
  });

  // Listen Drop Events on Media Containers
  $("body").on(
    "dragulaDrop.obsession",
    ".obsessions-page .js-media-pane .js-drag-and-drop-container",
    function (event, el, target) {
      const $dropped = $(el);
      const input = $dropped.find("input[name=media]");
      const id = input.val();
      const $siblings = $(target).children();
      const $pane = $dropped.parents(".js-media-pane");
      const obsession = $pane.data("obsession");
      const mediatype = $pane.data("mediatype");

      // Call the Update Media URL
      // We have to Remove 1 to the Position for the Dragula Mirror
      $.ajax({
        url: "/obsessions/" + obsession + "/medias/" + mediatype + "/" + id,
        method: "PUT",
        data: { position: $siblings.length - 1 - $dropped.index() - 1 },
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $dropped.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Clicks Events on Publish/Unpublish Media Buttons
  $("body").on(
    "click.obsession",
    [
      ".obsessions-page .js-media-pane .media-card .card-btn-hover .btn-success",
      ".obsessions-page .js-media-pane .media-card .card-btn-hover .btn-warning",
    ].join(", "),
    function (event) {
      const $clicked = $(event.currentTarget);
      const input = $clicked.parents(".media-card").find("input[name=media]");
      const id = input.val();
      const $pane = $clicked.parents(".js-media-pane");
      const obsession = $pane.data("obsession");
      const mediatype = $pane.data("mediatype");

      // Call the Update Media URL
      $.ajax({
        url: "/obsessions/" + obsession + "/medias/" + mediatype + "/" + id,
        method: "PUT",
        data: { publish: $clicked.hasClass("btn-success") },
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $clicked.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Drop Events on Episodes Containers
  $("body").on(
    "dragulaDrop.obsession",
    ".obsessions-page #episodes .js-drag-and-drop-container",
    function (event, el, target) {
      const $dropped = $(el);
      const input = $dropped.find("input[name=episode]");
      const id = input.val();
      const $siblings = $(target).children();
      const $pane = $dropped.parents("#episodes");
      const obsession = $pane.data("obsession");

      // Call the Update Media URL
      // We have to Remove 1 to the Position for the Dragula Mirror
      $.ajaxFormProcessPromise(
        $.ajax({
          url: "/obsessions/" + obsession + "/episodes/" + id,
          method: "PUT",
          data: { position: $siblings.length - 1 - $dropped.index() - 1 },
        })
      );
    }
  );

  // Listen Add Element Modal Display
  $("body").on(
    "show.bs.modal.obsession",
    [".obsessions-page #add-doc", ".obsessions-page #add-quoted-doc", ".obsessions-page #add-sound-doc"].join(", "),
    function (event) {
      const $button = $(event.relatedTarget);
      const $modal = $(this);

      // Update the Modal  Title
      $modal.find("h4 span").html($button.text());

      // Reset Data
      $modal.removeData("document");

      // Define Modal Parameters
      $modal.find(".js-element input[data-endpoint]").attr("data-endpoint", $button.data("endpoint"));

      // Reset Autocomplete Inputs
      $modal.find(".js-autocomplete").val("");
    }
  );

  // Listen Add Element/Link Modal Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.obsession-modals",
    [
      ".obsessions-page #add-doc        .js-element .js-autocomplete",
      ".obsessions-page #add-quoted-doc .js-element .js-autocomplete",
      ".obsessions-page #add-sound-doc  .js-sound   .js-autocomplete",
      ".obsessions-page #add-sound-doc  .js-element .js-autocomplete",
    ].join(", "),
    function (event, $selected) {
      // Get the Modal
      const $modal = $(event.currentTarget).parents(".modal");

      // Get & Save the Element Card
      $.ajax({
        url: $selected.data("endpoint") + "/card/" + $selected.data("id"),
        method: "GET",
        dataType: "json",
        headers: {
          Accept: "application/json",
        },
      }).done(function (data) {
        if ($selected.parents(".js-sound").length > 0) {
          // Save the Data & Update the Autocomplete Input
          $modal.data("sound", data);
          $modal.find(".js-sound .js-autocomplete").val($selected.textContent());
        } else {
          // Save the Data & Update the Autocomplete Input
          $modal.data("document", data);
          $modal.find(".js-element .js-autocomplete").val($selected.find(".wrap-text").textContent());
        }
      });
    }
  );

  // Listen Copy Episode Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.obsession",
    ".obsessions-page .js-copy-episode .js-autocomplete",
    function (event, $selected) {
      // Get Selected Element Information
      const id = $selected.data("id");
      const $pane = $selected.parents(".js-copy-episode");
      const obsession = $pane.data("obsession");

      // Call the Add Media URL
      $.ajax({
        url: "/obsessions/" + obsession + "/copy-episode/" + id,
        method: "POST",
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
      }).done(function () {
        // Reload the Page on the Correct Tab
        window.location.href =
          window.location.href.replace(/#[a-zA-Z0-9-]+$/, "") + "#" + $selected.parents(".tab-pane").attr("id");
        window.location.reload(true);
      });
    }
  );

  // Listen Clicks on Board Dropdown Elements to Fix Background Issue
  $("body").on("click.obsession", ".obsessions-page .nav a[role=tab]", function (event) {
    const $clicked = $(event.currentTarget);
    const $nav = $clicked.parents(".nav");

    // Remove .active class on Nav Elements
    $nav.find(".active").removeClass("active");
  });

  // Listen Clicks on Mini/Note Copy Button
  $("body").on(
    "click.obsession",
    ".obsessions-page #mini .js-copy-field, .obsessions-page #note .js-copy-field",
    function (event) {
      const $clicked = $(event.currentTarget);
      const $modal = $(".obsessions-page #copy-field");
      const episode = $clicked.data("copy-episode");
      const field = $clicked.data("copy-field");
      const key = $clicked.data("copy-key");

      // Copy input fields into the Modal
      $modal.find("input[name=field]").val(field);
      $modal.find("input[name=key]").val(key);

      // Update the Form Action
      $modal.find("form").attr("action", "/episodes/" + episode + "/copy-field");

      // Show the Modal
      $modal.modal("show");
    }
  );

  // Listen Mini/Note Copy Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.obsession",
    ".obsessions-page #copy-field .js-autocomplete",
    function (event, $selected) {
      // Get Selected Element Information
      const id = $selected.data("id");
      const endpoint = $selected.data("endpoint");

      // Get the Card
      $.ajax({
        url: endpoint + "/figure/" + id,
        method: "GET",
        dataType: "html",
        headers: {
          Accept: "text/html",
        },
      }).done(function (card) {
        // Append the Card to the List
        $selected.parents(".modal-body").find(".js-selected-content").html(card);
      });
    }
  );

  // Listen Add Author Autocomplete Selection Events
  $("body").on(
    "autocomplete.selected.obsession",
    ".obsessions-page #add-author .js-autocomplete",
    function (event, $selected) {
      // Get Selected Element Information
      const id = $selected.data("id");
      const text = $selected.data("text");
      const $dropdown = $selected.parents(".dropdown");
      const $input = $dropdown.find(".js-autocomplete");

      // Update the Autocomplete
      $input.data("author", id);
      $input.val(text);
    }
  );
  // Update the Author Value before Any Submit
  $("body").on("click.obsession", ".obsessions-page #add-author form .btn-primary", function (event) {
    // Get the jQuery Input
    const $clicked = $(event.currentTarget);
    const $form = $clicked.parents("form");
    const $author = $form.find("input[name=author]");

    // Check if the Input has a data-author attribute
    if ($author.data("author")) {
      // Replace the Value by it's data-author attribute
      $author.val($author.data("author"));
    }

    // Submit the Form
    $form.get(0).submit();
  });
});
