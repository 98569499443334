import "@popperjs/core";
import * as bootstrap from "bootstrap";
import $ from "jquery";
import "jquery-contextmenu/dist/jquery.contextMenu";
import lazySizes from "lazysizes";
import moment from "moment";
import "moment/locale/fr";

// Moment configuration
moment.locale("fr");

// LazySizes configuration
lazySizes.cfg.loadMode = 1; // load also very near view elements
lazySizes.cfg.loadHidden = false; // don't load visibility: hidden; elements
lazySizes.cfg.ricTimeout = 300; // timeout option used for the requestIdleCallback

// Attach jQuery & bootstrap to the window
window.$ = $;
window.jQuery = $;
window.bootstrap = bootstrap;

// Import all function
function importAll(r) {
  r.keys().forEach(r);
}

// Require libraries
importAll(require.context("./libs/", true, /\.js$/));
importAll(require.context("./app/", true, /\.js$/));

$(function () {
  "use strict";

  // Add a js class to the body
  $(document.body).addClass("js");
});
