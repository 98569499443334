
      import pug from 'pug-runtime';

      function episodesFormsSlideshow(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (episode, media, printDate, resimg) {
      pug_html = pug_html + "\u003Cform" + (" class=\"row js-form\""+" method=\"put\""+pug.attr("action", "/episodes/" + episode.id + "/slideshow", true, false)+" data-ajax-form-key=\"episode-form-slideshow\"") + "\u003E\u003Cdiv class=\"col-12 mb-3\"\u003E\u003Clabel\u003ELes images du diaporama\u003C\u002Flabel\u003E\u003Cbutton class=\"btn btn-success float-end\" type=\"submit\"\u003EEnregistrer\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-12 card-deck-wrapper\"\u003E\u003Cdiv class=\"row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xxl-5 g-2 js-drag-and-drop-container\"\u003E";
if (episode.slideshow) {
// iterate episode.slideshow
;(function(){
  var $$obj = episode.slideshow;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var image = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E\u003Cdiv class=\"card\"\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E\u003Cbutton class=\"btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" type=\"hidden\" name=\"media\""+pug.attr("value", image.id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", image.img ? resimg(image.img, "480") : image.files[0], true, false)+pug.attr("alt", image.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-body\"\u003E";
if (image.subtitle) {
pug_html = pug_html + "\u003Ch4 class=\"card-title\"\u003E" + (null == (pug_interp = image.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003Cp class=\"card-text\"\u003E" + (null == (pug_interp = image.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003E" + (null == (pug_interp = image.title) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
if (image.date) {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003ELe " + (pug.escape(null == (pug_interp = printDate(media.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cp class=\"card-text text-center\"\u003E\u003Ca" + (" class=\"btn btn-primary\""+pug.attr("href", image.endpoint + "/" + image.id, true, false)) + "\u003EAfficher\u003C\u002Fa\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var image = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E\u003Cdiv class=\"card\"\u003E\u003Cdiv class=\"card-btn card-btn-top card-btn-left\"\u003E\u003Cbutton class=\"btn btn-danger\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cinput" + (" type=\"hidden\" name=\"media\""+pug.attr("value", image.id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", image.img ? resimg(image.img, "480") : image.files[0], true, false)+pug.attr("alt", image.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-body\"\u003E";
if (image.subtitle) {
pug_html = pug_html + "\u003Ch4 class=\"card-title\"\u003E" + (null == (pug_interp = image.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003Cp class=\"card-text\"\u003E" + (null == (pug_interp = image.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003E" + (null == (pug_interp = image.title) ? "" : pug_interp) + "\u003C\u002Fp\u003E";
}
if (image.date) {
pug_html = pug_html + "\u003Cp class=\"card-text\"\u003ELe " + (pug.escape(null == (pug_interp = printDate(media.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Cp class=\"card-text text-center\"\u003E\u003Ca" + (" class=\"btn btn-primary\""+pug.attr("href", image.endpoint + "/" + image.id, true, false)) + "\u003EAfficher\u003C\u002Fa\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";
    }.call(this, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "media" in locals_for_with ?
        locals_for_with.media :
        typeof media !== 'undefined' ? media : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined));
    ;;return pug_html;}

      export default episodesFormsSlideshow;
    