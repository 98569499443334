import $ from "jquery";

$(function () {
  // Listen Tag Search Autocomplete Selection Events
  $("body").on("autocomplete.selected.tag", ".tags-page #search-tag .js-autocomplete", function (event, $selected) {
    // Get Selected Element Information
    const id = $selected.data("id");

    // Navigate to the Tag Page
    window.location.href = "/tags/" + id;
  });

  // Listen Click on Episodes Cards' Remove Button
  $("body").on("click.tag", ".tags-page #tag .card .btn-danger", function (event) {
    // Get the Card
    const $button = $(event.currentTarget);
    const $card = $button.parents(".card");
    const $tab = $button.parents(".tab-pane");
    const episode = $card.find("input[name=episode]");

    // Create a New Tag
    $.ajax({
      url: "/tags/" + $tab.data("id") + "/" + episode.val(),
      method: "DELETE",
    })
      .done(function () {
        // Display a Success Message
        $.notifySuccess({
          title: "Hey !",
          message: "Adrienne a bien supprimé le tag pour cet Épisode.",
        });

        // Remove the Card
        $card.parent().remove();
      })
      .fail(function (jqXHR) {
        // Display an Error Message
        switch (jqXHR.status) {
          case 403:
            $.notifyDanger({
              title: "Oups !",
              message: "Vous n’avez pas le droit de modifier cet Épisode.",
            });
            break;
          default:
            $.notifyDanger({
              title: "Oups !",
              message: "Adrienne n’a pas pu supprimer le tag de cet Épisode.",
            });
        }
      });
  });
});
