import { Namespace, TempusDominus, Unit, extend, loadLocale, locale } from "@eonasdan/tempus-dominus";
import { localization, name } from "@eonasdan/tempus-dominus/dist/locales/fr";
import { load } from "@eonasdan/tempus-dominus/dist/plugins/fa-five";
import $ from "jquery";

// Load the FR locale
loadLocale({ localization: localization, name: name });
locale(name); // Set the default locale to FR

// Use Font Awesome 5 for the icons
extend(load);

const dtPickerDataKey = "dtPicker";
const dtPickerUseClockKey = "dtPickerUseClock";
const eventsNamespace = "lesjours.datetimepicker";

// Function to Initialize a Datetime Picker
function initializeDatetimePicker(domElement) {
  // Get jQuery Element
  const $el = $(domElement);

  // Check if the Element is already Initialized
  if ($el.data(dtPickerDataKey) !== undefined) {
    // Skip the Current Element
    return;
  }

  // Picker format
  let pickerFormat = $el.data("datetimepickerFormat") ? $el.data("datetimepickerFormat") : "L LT";
  let useClock = pickerFormat === "L LT";
  $el.data(dtPickerUseClockKey, useClock);

  // Initialize the Current Element
  $el.data(
    dtPickerDataKey,
    new TempusDominus($el.get(0), {
      useCurrent: false,
      defaultDate: $el.data("datetimepickerDefault") ? new Date($el.data("datetimepickerDefault")) : undefined,
      restrictions: {}, // No minDate & maxDate restrictions
      display: {
        sideBySide: useClock,
        components: {
          clock: useClock,
        },
      },
      localization: {
        format: pickerFormat,
      },
    })
  );

  // Add change.td event listener
  $el.on(Namespace.events.change, function (event) {
    // Filter extras events
    if (event.namespace !== "td") {
      return;
    }

    // Get the Current Target & Its Form
    const $target = $(event.currentTarget);
    const $form = $target.parents("form");

    // Get the input
    const $input =
      $target.data("datetimepickerTargetInput") !== undefined
        ? $form.find('input[name="' + $target.data("datetimepickerTargetInput") + '"]')
        : $form.find("input[name=date]");

    // Check if we have a Date
    if (typeof event.date !== "object") {
      // Update the Date Input
      $input.val("");
      return;
    }

    // Check if it's a date-only field
    if ($el.data(dtPickerUseClockKey) === false) {
      // Ensure that we don't have a time-part in the date
      event.date.startOf(Unit.date);
    }

    // Update the Date Input
    $input.val(event.date.toISOString());
  });
}

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// JQuery functions
$.fn.initializeDatetimePicker = function () {
  return this.each((idx, el) => initializeDatetimePicker(el));
};

// Listen ajaxFormProcessed Events
$("body").on(`ajaxFormProcessed.${eventsNamespace}`, function () {
  // Initialize datetime pickers
  $(".js-datetime-picker").each((idx, el) => initializeDatetimePicker(el));
});

// Initialize on page load
$(function () {
  // Initialize datetime pickers
  $(".js-datetime-picker").each((idx, el) => initializeDatetimePicker(el));
});
