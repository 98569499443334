
      import pug from 'pug-runtime';

      function mediasDropzonesResized(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (resized) {
      pug_html = pug_html + "\u003Cdiv class=\"alert alert-info small m-0\"\u003E\u003Cspan\u003E\u003Cstrong\u003ETaille&nbsp;:\u003C\u002Fstrong\u003E" + (pug.escape(null == (pug_interp = resized.size.width) ? "" : pug_interp)) + "x" + (pug.escape(null == (pug_interp = resized.size.height) ? "" : pug_interp)) + "&nbsp;-&nbsp;" + (pug.escape(null == (pug_interp = resized.length) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cbr\u002F\u003E\u003Cspan\u003E\u003Cstrong\u003EQuality&nbsp;:\u003C\u002Fstrong\u003E" + (pug.escape(null == (pug_interp = resized.quality) ? "" : pug_interp)) + "%\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "resized" in locals_for_with ?
        locals_for_with.resized :
        typeof resized !== 'undefined' ? resized : undefined));
    ;;return pug_html;}

      export default mediasDropzonesResized;
    