
      import pug from 'pug-runtime';

      function newslettersCardsArticle(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (element, printDate) {
      pug_html = pug_html + "\u003Cdiv class=\"list-group-item\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"model\""+pug.attr("value", element.model, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"article\""+pug.attr("value", element.article.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"d-flex w-100 justify-content-between\"\u003E\u003Cdiv class=\"flex-grow-1 pe-4\"\u003E\u003Cdiv class=\"d-flex w-100 justify-content-between\"\u003E\u003Ch5 class=\"mb-1\"\u003E" + (null == (pug_interp = element.article.title) ? "" : pug_interp) + "\u003C\u002Fh5\u003E\u003Csmall\u003E" + (pug.escape(null == (pug_interp = printDate(element.article.date)) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"mb-1\"\u003E" + (null == (pug_interp = element.article.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-danger d-inline my-auto px-3\"\u003EEnlever\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "element" in locals_for_with ?
        locals_for_with.element :
        typeof element !== 'undefined' ? element : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined));
    ;;return pug_html;}

      export default newslettersCardsArticle;
    