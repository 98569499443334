import $ from "jquery";

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// Listen Submit Events on Form Elements
$("body").on("change.inputValidation", "form input[data-validation-endpoint]", function (event) {
  // Prevent Default Actions
  event.preventDefault();

  // Get the Input
  const $input = $(event.currentTarget);

  // Send a jQuery Request
  $.ajax({
    url: $input.data("validationEndpoint"),
    method: "GET",
    data: { input: $input.val() },
    dataType: "json",
    headers: {
      Accept: "application/json",
    },
  }).then(function (json) {
    // Get the input's parent
    const $formGrp = $input.parent();

    // Display a Message
    if (json.valid === true) {
      // Validate the Input
      $input.get(0).setCustomValidity("");

      // Remove Field Error Message
      $input.removeClass("is-invalid");
      $formGrp.find(".invalid-feedback").remove();
    } else {
      // Invalidate the Input
      $input.get(0).setCustomValidity(json.message);

      // Display an Error Message
      $input.addClass("is-invalid");
      $formGrp.append('<div class="invalid-feedback">' + json.message + "</div>");
    }
  });
});
