
      import pug from 'pug-runtime';

      function episodesFormsMinisSelect(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (minis, sortMinisNotesByDocField) {
      pug_html = pug_html + "\u003Cselect class=\"form-control\" data-editor-link-type=\"mini\" data-ajax-form-key=\"episode-form-minis-select\"\u003E";
if (minis) {
pug_html = pug_html + "\u003Coption value=\"\"\u003E\u003C\u002Foption\u003E";
// iterate sortMinisNotesByDocField(minis, "title")
;(function(){
  var $$obj = sortMinisNotesByDocField(minis, "title");
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var mini = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", mini.number, true, false)) + "\u003E" + (null == (pug_interp = !mini.title && mini.doc ? mini.doc.title : mini.title) ? "" : pug_interp) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var mini = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", mini.number, true, false)) + "\u003E" + (null == (pug_interp = !mini.title && mini.doc ? mini.doc.title : mini.title) ? "" : pug_interp) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fselect\u003E";
    }.call(this, "minis" in locals_for_with ?
        locals_for_with.minis :
        typeof minis !== 'undefined' ? minis : undefined, "sortMinisNotesByDocField" in locals_for_with ?
        locals_for_with.sortMinisNotesByDocField :
        typeof sortMinisNotesByDocField !== 'undefined' ? sortMinisNotesByDocField : undefined));
    ;;return pug_html;}

      export default episodesFormsMinisSelect;
    